import React, { Component } from "react";
import { connect } from "react-redux";
import { dashboardActions } from "../../../_actions";
import { Link } from "react-router-dom";

import Loader from "react-loader-spinner";
import { numberFormat } from "../../../_components/numberformat";
import Avatar from "react-avatar";

import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import LoanHistory from "./../LoanHistoryModal";
import ShimmerEffect from "../../../_components/ShimmerEffect";
class PartiallyPaidLoans extends Component {
  state = {
    pageNumber: 1,
    mpesa_code: "",
    phone_no: "",
    id_no: "",
    repayment: "",
    status: "",
    start_repayment_date: "",
    end_repayment_date: "",
    loanxid: "",
    showModal: false,
    name: "",
  };

  componentDidMount() {
    this.props.getDashboardPartiallyPaidLoans(1, "", "", "", "", "", "", "");
  }

  onChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  onSearch = () => {
    //console.log("ID NO is", this.state.id_no);
    const {
      pageNumber,
      id_no,
      phone_no,
      mpesa_code,
      repayment,
      status,
      start_repayment_date,
      end_repayment_date,
    } = this.state;
    this.props.getDashboardPartiallyPaidLoans(
      1,
      id_no,
      phone_no,
      mpesa_code,
      repayment,
      status,
      start_repayment_date,
      end_repayment_date
    );
  };
  //Display Loan Modal

  displayLoanModal = (e, loan, name) => {
    this.setState({
      loanxid: loan,
      showModal: true,
      name: name,
    });
  };
  //Closed Loans

  onClose = () => {
    this.setState({
      loanxid: "",
      showModal: false,
      name: "",
    });
  };

  _showTotals = (props) => {
    return (
      <tr>
        <td>Totals</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  };

  _displayLoanData = () => {
    const { partialpaidloans } = this.props;

    if (partialpaidloans.loans.data) {
      let principal = 0;
      return partialpaidloans.loans.data.data.map((loan, key) => {
        const colorCode = loan.remaining_days < 0 ? "#d9002e" : "#00a039";
        let name = loan.users.first_name + " " + loan.users.last_name;

        //if (loan.remaining_days === 0) {
        return (
          <tr key={key}>
            <td
              style={{
                borderLeftColor: `${colorCode}`,
                borderLeftWidth: 6,
                marginLeft: 50,
              }}
            >
              {" "}
              <Avatar
                name={loan.users.first_name + " " + loan.users.last_name}
                size="30"
                round={true}
              />{" "}
            </td>
            <td>
              <Link to={"/customer/" + loan.users.id}>
                {loan.users.first_name} {loan.users.last_name}
              </Link>
            </td>

            <td>{loan.created_at}</td>
            <td>{loan.proposed_repayment_time}</td>

            <td className="center ">{Number(loan.amount).toFixed(2)}</td>
            <td className="center">
              {Number(loan.to_repay_amount).toFixed(2)}
            </td>
            <td className="center">
              {" "}
              {Number(loan.to_repay_amount - loan.amount).toFixed(2)}
            </td>
            <td className="center bg-active text-white">
              {Number(loan.to_repay_amount - loan.amount_repaid).toFixed(2)}
            </td>
            <td className="center">{Number(loan.amount_repaid).toFixed(2)}</td>

            <td className="center">{Number(loan.amount_repaid).toFixed(2)}</td>
            <td className="capitalize text-warning">{loan.status}</td>
            <td
              style={{
                borderRightColor: `${colorCode}`,
                borderRightWidth: 6,
              }}
            >
              <button
                className="btn btn-sm btn-outline-primary"
                data-toggle="modal"
                data-target={"#" + loan.id}
                onClick={(e) => this.displayLoanModal(e, loan.id, name)}
              >
                <i className="fa fa-eye"></i> View
              </button>
            </td>
          </tr>
        );
        // }
      });
    }
  };

  render() {
    const { partialpaidloans } = this.props;

    const {
      id_no,
      phone_no,
      mpesa_code,
      repayment,
      status,
      start_repayment_date,
      end_repayment_date,
      loanxid,
      name,
    } = this.state;

    const displayLoading = partialpaidloans.loading && (
      <React.Fragment>
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Released</th>
              <th>Maturity</th>

              <th className="center">Principal</th>
              <th className="center">Due</th>
              <th className="center">Commission</th>
              <th className="center">Paid</th>
              <th className="center">Balance</th>

              <th className="center">Last Payment</th>

              <th>Status</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            <ShimmerEffect columns={12} rows={5} />
          </tbody>
        </table>
      </React.Fragment>
    );

    const displayError = partialpaidloans.error && (
      <div className="col-md-12 offset-md-1 mt-4">
        <span className="text-danger">ERROR: {partialpaidloans.error}</span>
      </div>
    );

    let renderPageNumbers;
    let to_active = this.props.match.params.id;

    let { pg_title } = "";

    /**
     * Pagination
     */
    const pageNumbers = [];
    // console.log("CURRENT PAGE IS", borrowers.items.data.current_page);
    if (partialpaidloans.total !== null) {
      for (
        let i = 1;
        i <= Math.ceil(partialpaidloans.total / partialpaidloans.per_page);
        i++
      ) {
        pageNumbers.push(i);
      }

      renderPageNumbers = pageNumbers.map((number) => {
        let classes = partialpaidloans.current_page === number ? "active" : "";

        if (
          number === 1 ||
          number === partialpaidloans.total ||
          (number >= partialpaidloans.current_page - 5 &&
            number <= partialpaidloans.current_page + 5)
        ) {
          return (
            <span
              key={number}
              className={classes}
              onClick={() =>
                this.props.getDashboardPartiallyPaidLoans(
                  number,
                  id_no,
                  phone_no,
                  mpesa_code,
                  repayment,
                  status,
                  start_repayment_date,
                  end_repayment_date
                )
              }
            >
              {number}
            </span>
          );
        }
      });
    }
    /**
     * End Pagination
     */

    /*Display All Active Loans*/
    const displayLoans = partialpaidloans.loans && (
      <React.Fragment>
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Released</th>
              <th>Maturity</th>
              <th className="center">Principal</th>
              <th className="center">Due</th>
              <th className="center">Commission</th>
              <th className="center">Paid</th>
              <th className="center">Balance</th>
              <th className="center">Last Payment</th>
              <th>Status</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>{this._displayLoanData()}</tbody>
        </table>

        <div className="pagination">
          <div className="pagination">
            <span
              onClick={() =>
                this.props.getDashboardPartiallyPaidLoans(
                  partialpaidloans.current_page - 1,
                  id_no,
                  phone_no,
                  mpesa_code,
                  repayment,
                  status,
                  start_repayment_date,
                  end_repayment_date
                )
              }
            >
              &laquo;Previous
            </span>
            {renderPageNumbers}
            <span
              onClick={() =>
                this.props.getDashboardPartiallyPaidLoans(
                  partialpaidloans.current_page + 1,
                  id_no,
                  phone_no,
                  mpesa_code,
                  repayment,
                  status,
                  start_repayment_date,
                  end_repayment_date
                )
              }
            >
              Next &raquo;
            </span>
          </div>
        </div>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
          <LoanHistory
            loan={this.state.loanxid}
            showModal={this.state.showModal}
            onModalClose={this.onClose}
            loanxid={loanxid}
            name={name}
          />
          <div className="widget-content">
            <Tabs>
              <TabList>
                <Tab>Partially Paid Loans</Tab>
              </TabList>

              <TabPanels style={{ paddingTop: 15 }}>
                <TabPanel>
                  <h2 className="pg-title">
                    Partially Paid Loans{" "}
                    <span className="filter">
                      <i className="fa fa-search"></i> Filter
                    </span>
                  </h2>
                  {/*Filter*/}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-sm-12">
                          <form className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>ID Number</label>

                                <input
                                  type="text"
                                  className="form-control"
                                  name="id_no"
                                  placeholder=""
                                  value={this.state.id_no}
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Phone No</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="phone_no"
                                  placeholder=""
                                  value={this.state.phone_no}
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>MPESA Code</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="mpesa_code"
                                  name="mpesa_code"
                                  value={this.state.mpesa_code}
                                  placeholder=""
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Status</label>
                                <select
                                  className="form-control"
                                  data-toggle="select2"
                                  id="status"
                                  onChange={this.onChange}
                                  name="status"
                                  value={this.state.status}
                                >
                                  <option value="">Select</option>

                                  <option value="disbursed">Active</option>
                                  <option value="repaid">Paid</option>
                                  <option value="partial">
                                    Partial Payment
                                  </option>
                                  <option value="overdue">Overdue</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Repayment Period</label>
                                <select
                                  className="form-control"
                                  onChange={this.onChange}
                                  name="repayment"
                                  value={this.state.repayment}
                                >
                                  <option value="">Select</option>
                                  <option value="7b30d804-8b05-4eb4-9aae-46819a77910e">
                                    15 Days
                                  </option>
                                  <option value="42611c27-310a-4547-82b6-50aa0d05ec4a">
                                    30 Days
                                  </option>
                                  <option value="6525c382-d109-4490-b8e8-2f74dc9afa09">
                                    60 Days
                                  </option>
                                  <option value="af0e248a-e2b2-45a4-b95a-efb61407db88">
                                    90 Days
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Date from</label>
                                <input
                                  type="date"
                                  id="start_repayment_date"
                                  value={this.state.start_repayment_date}
                                  className="form-control flatpickr-input active"
                                  name="start_repayment_date"
                                  placeholder="2019-10-03"
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Date to</label>
                                <input
                                  type="date"
                                  id="end_repayment_date"
                                  value={this.state.end_repayment_date}
                                  className="form-control flatpickr-input active"
                                  name="end_repayment_date"
                                  placeholder="2019-10-04"
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="form-group mt-30">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-block m-1 createTicket"
                                  onClick={this.onSearch}
                                >
                                  <i className="fa fa-search"></i> Filter
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <br></br>
                  </div>
                  <div className="table-responsive widget-content">
                    {/*end filter*/}
                    {displayLoading}
                    {displayError}
                    {displayLoans}
                  </div>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { partialpaidloans } = state;
  return { partialpaidloans };
}

const actionCreators = (dispatch, props) => {
  return {
    getDashboardPartiallyPaidLoans: (
      pageNumber,
      id_no,
      phone_no,
      mpesa_code,
      repayment,
      status,
      start_repayment_date,
      end_repayment_date
    ) => {
      dispatch(
        dashboardActions.getDashboardPartiallyPaidLoans(
          pageNumber,
          id_no,
          phone_no,
          mpesa_code,
          repayment,
          status,
          start_repayment_date,
          end_repayment_date
        )
      );
    },
  };
};

const connectedAllLoans = connect(mapState, actionCreators)(PartiallyPaidLoans);
export { connectedAllLoans as PartiallyPaidLoans };
