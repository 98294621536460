export const loanConstants = {
  GETALL_LOANS_REQUEST: "LOANS_GETALL_REQUEST",
  GETALL_LOANS_SUCCESS: "LOANS_GETALL_SUCCESS",
  GETALL_LOANS_FAILURE: "LOANS_GETALL_FAILURE",

  GETALL_DUELOANS_REQUEST: "DUELOANS_GETALL_REQUEST",
  GETALL_DUELOANS_SUCCESS: "DUELOANS_GETALL_SUCCESS",
  GETALL_DUELOANS_FAILURE: "DUELOANS_GETALL_FAILURE",

  GETALL_ACTIVELOANS_REQUEST: "ACTIVELOANS_GETALL_REQUEST",
  GETALL_ACTIVELOANS_SUCCESS: "ACTIVELOANS_GETALL_SUCCESS",
  GETALL_ACTIVELOANS_FAILURE: "ACTIVELOANS_GETALL_FAILURE",
  LOAD_NEW_PAGE: "LOAD_NEW_PAGE",
  LOAD_EXACT_PAGE: "LOAD_EXACT_PAGE",

  LOAN_HISTORY_REQUEST: "LOAN_HISTORY_REQUEST",
  LOAN_HISTORY_SUCCESS: "LOAN_HISTORY_SUCCESS",
  LOAN_HISTORY_FAILURE: "LOAN_HISTORY_FAILURE",

  GET_BORROWINGS_HISTORY_REQUEST: "GET_BORROWINGS_HISTORY_REQUEST",
  GET_BORROWING_HISTORY_SUCCESS: "GET_BORROWING_HISTORY_SUCCESS",
  GET_BORROWINGS_HISTORY_FAILURE: "GET_BORROWINGS_HISTORY_FAILURE",

  GET_REPAYMENTS_HISTORY_REQUEST: "GET_REPAYMENTS_HISTORY_REQUEST",
  GET_REPAYMENTS_HISTORY_SUCCESS: "GET_REPAYMENTS_HISTORY_SUCCESS",
  GET_REPAYMENTS_HISTORY_FAILURE: "GET_REPAYMENTS_HISTORY_FAILURE",
};
