import { API_URL } from "../_config/env";
import { authHeader } from "../_helpers";
import axios from "axios";

export const userService = {
  login,
  logout,
  newuser,
  getAllUsers,
  isValidUser,
  passwordUpdate
};

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${API_URL}/admin-authenticate`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      if (user.success === true) {
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("xhrid", user.data.token);
      }
      return user;
    });
}

function getAllUsers() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/admins`, requestOptions).then(handleResponse);
}

function passwordUpdate(data) {
  
  let user = JSON.parse(localStorage.getItem("user"));

  return axios.post(`${API_URL}/admin/password/change`, data,
  {
    headers: {
      Authorization: "Bearer " + user.data.token,
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
  }
   ).then(handleResponse);
}


/**
 * isValid User
 */
function isValidUser() {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/check_jwt`, requestOptions).then(handleResponse);
}

function newuser(first_name, last_name, phone_number, email, user_type) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };
  //console.log("Payload ", payload);
  let user = JSON.parse(localStorage.getItem("user"));

  var headers = {
    Authorization: "Bearer " + user.data.token,
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  };

  return axios
    .post(
      `${API_URL}/add_admin`,
      {
        first_name,
        last_name,
        phone_number,
        email,
        user_type,
      },
      {
        headers: {
          Authorization: "Bearer " + user.data.token,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }
    )
    .then(handleResponse);
}

function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("xhrid");
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        //auto logout
        logout();
        window.location.reload(true);
      }
      const error = (data && data.errors) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
