import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { activeLoanActions } from "../../_actions";
import Avatar from "react-avatar";

class ActiveLoans extends Component {
  componentDidMount() {
    console.log("DUNCAN ACTIVE LOANS", this.props);

    this.props.getActiveLoans();
  }

  /*Active Loans*/
  _displayAllActiveLoans = () => {
    const { activeloans } = this.props;

    if (activeloans.items.data) {
      const loandata = activeloans.items.data;

      // return this.props.activeloans.items.data.map((loan, key) => {});
      /*return activeloans.items.data.map((loan, key) => {
        return (
          <tr key={key}>
            <td>
              {" "}
              <Avatar
                name={loan.users.first_name + " " + loan.users.last_name}
                size="30"
                round={true}
              />
            </td>
            <td>
              {loan.users.first_name} {loan.users.last_name}
            </td>
            <td>
              <Link to="">{loan.transaction_code}</Link>
            </td>
            <td>{loan.created_at}</td>
            <td>{loan.proposed_repayment_time}</td>

            <td>{Number(loan.amount).toFixed(2)}</td>
            <td>{Number(loan.to_repay_amount).toFixed(2)}</td>
            <td>{Number(loan.amount_repaid).toFixed(2)}</td>
            <td>
              {" "}
              {Number(loan.to_repay_amount - loan.amount_repaid).toFixed(2)}
            </td>
            <td>{Number(loan.amount_repaid).toFixed(2)}</td>
            <td className="capitalize">{loan.status}</td>
          </tr>
        );
      });*/
    }
  };
  /*End Active Loans*/

  render() {
    const { activeloans } = this.props;

    console.log("LOADER IS ", activeloans.activeloading);

    const displayActiveLoading = activeloans.activeloading && (
      <div className="col-md-4 offset-md-5 mt-4">
        <Loader type="TailSpin" color="#2da3e0" height={30} width={30} />
      </div>
    );

    const displayError = activeloans.error && (
      <div className="col-md-12 offset-md-1 mt-4">
        <span className="text-danger">ERROR: {activeloans.error}</span>
      </div>
    );

    /*Display Active Loans */
    const displayActiveLoans = activeloans.items && (
      <div className="table-responsive widget-content">
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Loan ID</th>
              <th>Released</th>
              <th>Maturity</th>

              <th>Principal</th>
              <th>Due</th>
              <th>Paid</th>
              <th>Balance</th>
              <th>Last Payment</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>{this._displayAllActiveLoans()}</tbody>
        </table>
      </div>
    );

    return (
      <React.Fragment>
        {displayActiveLoading}
        {displayError}
        {displayActiveLoans}
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { activeloans } = state;
  return { activeloans };
}

const actionCreators = {
  getActiveLoans: activeLoanActions.getActiveLoans
};

const connectedActiveLoans = connect(mapState, actionCreators)(ActiveLoans);
export { connectedActiveLoans as ActiveLoans };
