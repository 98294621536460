import { loanConstants } from "../_constants";

export function activeloans(state = {}, action) {
  switch (action.type) {
    case loanConstants.GETALL_ACTIVELOANS_REQUEST:
      return {
        loading: true
      };
    case loanConstants.GETALL_ACTIVELOANS_SUCCESS:
      return {
        items: action.loans
      };
    case loanConstants.GETALL_ACTIVELOANS_FAILURE:
      return {
        error: action.error
      };
    default:
      return state;
  }
}


