import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";
import { history } from "../_helpers";
import { Redirect } from "react-router-dom";

export const userActions = {
  login,
  logout,
  addUser,
  getUsers,
  userValid,
};

function login(email, password) {
  return (dispatch) => {
    dispatch(request({ email }));
    userService.login(email, password).then(
      (user) => {
        if (user.success === false) {
          dispatch(failure(user.errors[0]));
          dispatch(alertActions.error(user.errors[0]));
        } else {
          dispatch(success(user));
          history.push("/dashboard");
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        dispatch(alertActions.error(error.toString()));
      }
    );
  };
  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function getUsers() {
  return (dispatch) => {
    dispatch(request());

    userService.getAllUsers().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return {
      type: userConstants.GETALL_USERS_REQUEST,
    };
  }

  function success(users) {
    return {
      type: userConstants.GETALL_USERS_SUCCESS,
      users,
    };
  }

  function failure(error) {
    return {
      type: userConstants.GETALL_USERS_FAILURE,
      error,
    };
  }
}

function userValid() {
  return (dispatch) => {
    dispatch(request());

    userService.isValidUser().then(
      (isvalid) => dispatch(success(isvalid)),
      (error) => dispatch(failure(error.toString()))
    );
  };
  function request() {
    return {
      type: userConstants.USER_VALID_REQUEST,
    };
  }
  function success(isvalid) {
    return {
      type: userConstants.USER_VALID_SUCCESS,
      isvalid,
    };
  }
  function failure(error) {
    return {
      type: userConstants.USER_VALID_FAILURE,
      error,
    };
  }
}

/**
 *
 * @param {*} first_name
 * @param {*} last_name
 * @param {*} phone_no
 * @param {*} email
 * @param {*} user_type
 */
function addUser(first_name, last_name, phone_number, email, user_type) {
  return (dispatch) => {
    dispatch(request(first_name, last_name, phone_number, email, user_type));

    userService
      .newuser(first_name, last_name, phone_number, email, user_type)
      .then(
        (newuser) => dispatch(success(newuser)),
        (error) => dispatch(failure(error.toString()))
      );
  };
}

function request(first_name, last_name, phone_no, email, user_type) {
  return {
    type: userConstants.NEW_USER_REQUEST,
  };
}

function success(newuser) {
  return {
    type: userConstants.NEW_USER_SUCCESS,
    addUser,
  };
}

function failure(error) {
  return {
    type: userConstants.NEW_USER_FAILURE,
    error,
  };
}

function logout() {
  userService.logout();
  return {
    type: userConstants.LOGOUT,
  };
}
