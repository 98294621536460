import React from "react";

import { Router, Route, Switch, Redirect, withRouter } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { history } from "./_helpers";
import { alertActions } from "./_actions";
import Routes from "./containers/Routes";

class App extends React.Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      //clear alerts on location change
      //this.props.alertActions;
    });
  }

  render() {
    const { alert } = this.props;

    if (alert.message) {
      toast.error(alert.message);
    }
    //console.log("Authentication is ", this.props.authentication.loggedIn);
 
    return (
      <React.Fragment>
        <ToastContainer position="top-right" />
        <Routes />
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { alert, authentication } = state;
  return { alert, authentication };
}

const actionCreators = {
  clearAlerts: alertActions.clear
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
