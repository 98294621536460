import React, { Component } from "react";
import { ListUsers } from "./ListUsers";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ShimmerEffect from "../../_components/ShimmerEffect";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import Avatar from "react-avatar";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Pagination } from "react-laravel-paginex";
import { userActions } from "../../_actions";

class User extends Component {
  state = {
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    user_type: "",
  };

  onChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = () => {
    const {
      first_name,
      last_name,
      phone_number,
      email,
      user_type,
    } = this.state;
    this.props.addUser(first_name, last_name, phone_number, email, user_type);
  };
  render() {
    const { newuser } = this.props;

    const displayLoading = newuser.loading && (
      <div className="col-md-4 offset-md-5 mt-4">
        <Loader type="TailSpin" color="#2da3e0" height={30} width={30} />
      </div>
    );

    const displayError = newuser.error && (
      <div className="col-md-12 offset-md-1 mt-4">
        <div className="alert alert-danger">
          <span className="text-danger">ERROR: {newuser.error}</span>
        </div>
      </div>
    );
    return (
      <React.Fragment>
        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
          <div className="widget-content">
            <Tabs>
              <TabList>
                <Tab>System Users</Tab>
                <Tab>Add new user</Tab>
              </TabList>

              <TabPanels style={{ paddingTop: 15 }}>
                <TabPanel>
                  <h2 className="pg-title">
                    System users &nbsp;
                    <span className="filter">
                      <i className="fa fa-search"></i> Filter
                    </span>
                  </h2>
                  {/*filter*/}

                  {/*end filter*/}
                  <div className="table-responsive widget-content">
                    <ListUsers />
                  </div>
                </TabPanel>

                <TabPanel>
                  <h2 className="pg-title">New system user form &nbsp;</h2>

                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-sm-12">
                          <form className="row">
                            <div className="col-lg-6">
                              <div className="form-group ">
                                <label>First Name</label>

                                <input
                                  type="text"
                                  className="form-control"
                                  name="first_name"
                                  id="first_name"
                                  placeholder=""
                                  value={this.state.first_name}
                                  onChange={this.onChange}
                                />
                              </div>
                              <div className="form-group">
                                <label>Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="last_name"
                                  id="last_name"
                                  placeholder=""
                                  value={this.state.last_name}
                                  onChange={this.onChange}
                                />
                              </div>
                              <div className="form-group">
                                <label>Email</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  value={this.state.email}
                                  placeholder=""
                                  onChange={this.onChange}
                                />
                              </div>
                              <div className="form-group">
                                <label>Phone No</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="phone_number"
                                  placeholder=""
                                  value={this.state.phone_number}
                                  onChange={this.onChange}
                                />
                              </div>
                              <div className="form-group">
                                <label>User type</label>
                                <select
                                  className="form-control"
                                  data-toggle="select2"
                                  id="user_type"
                                  onChange={this.onChange}
                                  name="user_type"
                                  value={this.state.user_type}
                                >
                                  <option value="">Select</option>

                                  <option value="1">Agent</option>
                                  <option value="0">Admin</option>
                                </select>
                              </div>

                              <div className="form-group mt-30">
                                {displayLoading}
                                {displayError}
                                <button
                                  type="button"
                                  className="btn btn-primary m-1 pull-right createTicket"
                                  onClick={this.onSubmit}
                                >
                                  <i className="fa fa-plus"></i> Add new User
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { newuser } = state;
  return { newuser };
}

const actionCreators = (dispatch, props) => {
  return {
    addUser: (first_name, last_name, phone_number, email, user_type) => {
      dispatch(
        userActions.addUser(
          first_name,
          last_name,
          phone_number,
          email,
          user_type
        )
      );
    },
  };
};

const connectedUser = connect(mapState, actionCreators)(User);
export { connectedUser as User };
