import React, { Component } from "react";

export default class UserModal extends Component {
  state = {
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    user_type: "",
  };

  componentDidMount() {
    this.setState({
      first_name: this.props.userData.first_name,
      last_name: this.props.userData.last_name,
      phone_number: this.props.userData.phone_number,
      email: this.props.userData.email,
      user_type: this.props.userData.user_type == 0 ? "Admin" : "Agent",
    });
  }

  onChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = () => {
    const {
      first_name,
      last_name,
      phone_number,
      email,
      user_type,
    } = this.state;
    this.props.addUser(first_name, last_name, phone_number, email, user_type);
  };

  render() {
    const { userData, user_id, showModal } = this.props;

    if (showModal === false) {
      return "";
    } else {
      return (
        <React.Fragment>
          <div id={user_id} className="modal col-md-12">
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content col-md-12">
                <div className="modal-header">
                  <div className="col-md-12">
                    <h5 className="modal-title" id="exampleModalLabel">
                      {userData.first_name + " " + userData.last_name}
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() =>
                        this.props.onModalClose({
                          user_id: "",
                          user: "",
                          showModal: false,
                        })
                      }
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>

                <div className="modal-body">
                  <div className="col-md-12">
                    <br></br>
                  </div>
                  <div className="card1">
                    <div className="card-body2">
                      <div className="row mb-2">
                        <div className="col-sm-12">
                          <form className="row">
                            <div className="col-lg-6">
                              <div className="form-group ">
                                <label>First Name</label>

                                <input
                                  type="text"
                                  className="form-control"
                                  name="first_name"
                                  id="first_name"
                                  placeholder=""
                                  value={this.state.first_name}
                                  onChange={this.onChange}
                                />
                              </div>
                              <div className="form-group">
                                <label>Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="last_name"
                                  id="last_name"
                                  placeholder=""
                                  value={this.state.last_name}
                                  onChange={this.onChange}
                                />
                              </div>
                              <div className="form-group">
                                <label>Email</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  value={this.state.email}
                                  placeholder=""
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label>Phone No</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="phone_number"
                                  placeholder=""
                                  value={this.state.phone_number}
                                  onChange={this.onChange}
                                />
                              </div>

                              <div className="form-group">
                                <label>User type</label>
                                <select
                                  className="form-control"
                                  data-toggle="select2"
                                  id="user_type"
                                  onChange={this.onChange}
                                  name="user_type"
                                  value={this.state.user_type}
                                >
                                  <option value="">Select</option>

                                  <option value="1">Agent</option>
                                  <option value="0">Admin</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <label>Status</label>
                                <select
                                  className="form-control"
                                  data-toggle="select2"
                                  id="user_type"
                                  onChange={this.onChange}
                                  name="user_type"
                                  value={this.state.user_type}
                                >
                                  <option value="">Select</option>

                                  <option value="1">Active</option>
                                  <option value="0">Deactivate</option>
                                </select>
                              </div>

                              <div className="form-group">
                                {/*displayLoading*/}
                                {/*displayError*/}
                                <button
                                  type="button"
                                  className="btn btn-primary m-1 pull-right createTicket"
                                  onClick={this.onSubmit}
                                >
                                  Update User <i className="fa fa-reply"></i>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}
