import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { numberFormat } from "../../_components/numberformat";
import ShimmerEffect from "../../_components/ShimmerEffect";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import Avatar from "react-avatar";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Pagination } from "react-laravel-paginex";

import { borrowerActions } from "../../_actions";

class BorrowersList extends Component {
  state = {
    id_no: "",
    phone_no: "",
    email: "",
    name: "",
    status: "",
    start_create_date: "",
    end_create_date: "",
  };

  componentDidMount() {
    this.props.getAllBorrowers(1, "", "", "", "", "", "", "");
  }

  onChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  onSearch = () => {
    //console.log("ID NO is", this.state.id_no);
    const {
      pageNumber,
      id_no,
      phone_no,
      email,
      name,
      status,
      start_create_date,
      end_create_date,
    } = this.state;

    this.props.getAllBorrowers(
      1,
      id_no,
      phone_no,
      email,
      name,
      status,
      start_create_date,
      end_create_date
    );
  };

  _displayBorrowers = () => {
    const { borrowers } = this.props;




    let to_active = this.props.match.params.id;

    if (borrowers.items.data) {
      let position = borrowers.items.data.from - 1;

      let borrowersArray = borrowers.items.data;
      if (borrowersArray.length !== 0) {
        return borrowers.items.data.data.map((loan, key) => {
          /*const loanPrincipal =
            loan.loans.length > 0 ? loan.loans[0]["amount"] : 0.0;

          const loanAmount =
            loan.loans.length > 0 ? loan.loans[0]["to_repay_amount"] : 0.0;
          const paidAmount =
            loan.loans.length > 0 ? loan.loans[0]["amount_repaid"] : 0.0;
          const loanStatus = loan.loans.length > 0 ? "Active" : "Inactive";
          const colorCode = loan.risk_level === null ? "#d9002e" : "#00a039";

          const cum_principal = loan.cumulative_values.total_borrowed;
          const cum_loan = loan.cumulative_values.total_to_repay_amount;
          const cum_repayment = loan.cumulative_values.total_amount_repaid;
          const active_principal =
            loan.cumulative_values.active_loan_amount_requested;

          const active_loan = loan.cumulative_values.active_loan_amount_to_pay;*/

          if (to_active != "undefined") {
            //if (cum_principal > 0) {
            position++;
            return (
              <tr key={key}>
                <td>{position}</td>
                <td className="text-primary">
                  <Avatar
                    name={loan.first_name + " " + loan.last_name}
                    size="30"
                    round={true}
                  />{" "}
                  <Link to={"/customer/" + loan.id} className="text-primary">
                    {loan.first_name} {loan.last_name}
                  </Link>
                </td>
                <td>
                  {" "}
                  <Link to={"/customer/" + loan.id}>{loan.phone_no}</Link>
                </td>

                <td>
                  {loan.credit_score > 0
                    ? numberFormat(loan.credit_score).toString()
                    : "0.00"}
                </td>
                <td>{loan.member_since}</td>
                <td>{loan.status}</td>

                <td className="align-items-center">
                  <Link to={"/customer/" + loan.id} className="text-success mr-3">
                    <i className="fa fa-eye"></i> View
                  </Link>
                  <Link to={"/customer/update/" + loan.id} className="text-primary">
                    <i className="fa fa-edit"></i> Edit
                  </Link>
                </td>

              </tr>
            );
            //}
          } else {
            //if (cum_principal > 0) {
            position++;
            return (
              <tr key={key}>
                <td>{position}</td>
                <td className="text-primary">
                  <Avatar
                    name={loan.first_name + " " + loan.last_name}
                    size="30"
                    round={true}
                  />{" "}
                  <Link to={"/customer/" + loan.id} className="text-primary">
                    {loan.first_name} {loan.last_name}
                  </Link>
                </td>
                <td>
                  {" "}
                  <Link to={"/customer/" + loan.id}>{loan.phone_no}</Link>
                </td>
                <td>
                  {loan.credit_score > 0
                    ? numberFormat(loan.credit_score).toString()
                    : "0.00"}
                </td>
                <td>{loan.member_since}</td>
                <td>{loan.status}</td>

                <td className="align-items-center">
                  <Link to={"/customer/" + loan.id} className="text-success">
                    <i className="fa fa-eye"></i> View
                  </Link>
                </td>
              </tr>
            );
          }
        });
      } else {
        //No data to display
      }
    }
  };

  render() {
    const { borrowers } = this.props;

    const {
      id_no,
      phone_no,
      email,
      name,
      status,
      start_create_date,
      end_create_date,
    } = this.state;
    let renderPageNumbers;
    let to_active = this.props.match.params.id;

    let { pg_title } = "";

    if (to_active == "undefined") {
    } else {
      if (to_active == 1) {
        pg_title = "Active Borrowers";
      } else {
        pg_title = "Registered Borrowers";
      }
    }

    const displayLoading = borrowers.loading && (
      <React.Fragment>
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Customer Name</th>
              <th>Phone No</th>
              <th>Loan Limit</th>
              <th>Member Since</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <ShimmerEffect columns={7} rows={5} />
          </tbody>
        </table>
      </React.Fragment>
    );
    const displayError = borrowers.error && (
      <div className="col-md-12 offset-md-1 mt-4">
        <span className="text-danger">ERROR: {borrowers.error}</span>
      </div>
    );

    /**
     * Pagination
     */
    const pageNumbers = [];
    // console.log("CURRENT PAGE IS", borrowers.items.data.current_page);
    if (borrowers.total !== null) {
      for (
        let i = 1;
        i <= Math.ceil(borrowers.total / borrowers.per_page);
        i++
      ) {
        pageNumbers.push(i);
      }

      renderPageNumbers = pageNumbers.map((number) => {
        let classes = borrowers.current_page === number ? "active" : "";

        if (
          number === 1 ||
          number === borrowers.total ||
          (number >= borrowers.current_page - 5 &&
            number <= borrowers.current_page + 5)
        ) {
          return (
            <span
              key={number}
              className={classes}
              onClick={() =>
                this.props.getAllBorrowers(
                  number,
                  id_no,
                  phone_no,
                  email,
                  name,
                  status,
                  start_create_date,
                  end_create_date
                )
              }
            >
              {number}
            </span>
          );
        }
      });
    }
    /**
     * End Pagination
     */

    const displayBorrowers = borrowers.items && (
      <React.Fragment>
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Customer Name</th>
              <th>Phone No</th>
              <th>Loan Limit</th>
              <th>Member Since</th>

              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{this._displayBorrowers()}</tbody>
        </table>
        <div className="pagination">
          <div className="pagination">
            <span
              onClick={() =>
                this.props.getAllBorrowers(borrowers.current_page - 1)
              }
            >
              &laquo;Previous
            </span>
            {renderPageNumbers}
            <span
              onClick={() =>
                this.props.getAllBorrowers(borrowers.current_page + 1)
              }
            >
              Next &raquo;
            </span>
          </div>
        </div>
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
          <div className="widget-content">
            <Tabs>
              <TabList>
                <Tab>{pg_title}</Tab>
              </TabList>

              <TabPanels style={{ paddingTop: 15 }}>
                <TabPanel>
                  <h2 className="pg-title">
                    {pg_title}&nbsp;
                    <span className="filter">
                      <i className="fa fa-search"></i> Filter
                    </span>
                  </h2>
                  {/*filter*/}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-sm-12">
                          <form className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>ID Number</label>

                                <input
                                  type="text"
                                  className="form-control"
                                  name="id_no"
                                  placeholder=""
                                  value={this.state.id_no}
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Phone No</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="phone_no"
                                  placeholder=""
                                  value={this.state.phone_no}
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  name="name"
                                  value={this.state.name}
                                  placeholder=""
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Email</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  value={this.state.email}
                                  placeholder=""
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Loan Status</label>
                                <select
                                  className="form-control"
                                  data-toggle="select2"
                                  id="status"
                                  onChange={this.onChange}
                                  name="status"
                                  value={this.state.status}
                                >
                                  <option value="">Select</option>

                                  <option value="disbursed">Active</option>
                                  <option value="repaid">Paid</option>
                                  <option value="partial">
                                    Partial Payment
                                  </option>
                                  <option value="overdue">Overdue</option>
                                </select>
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Registered date from</label>
                                <input
                                  type="date"
                                  id="start_repayment_date"
                                  value={this.state.start_create_date}
                                  className="form-control flatpickr-input active"
                                  name="start_repayment_date"
                                  placeholder="2019-10-03"
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Date to</label>
                                <input
                                  type="date"
                                  id="end_repayment_date"
                                  value={this.state.end_create_date}
                                  className="form-control flatpickr-input active"
                                  name="end_repayment_date"
                                  placeholder="2019-10-04"
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="form-group mt-30">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-block m-1 createTicket"
                                  onClick={this.onSearch}
                                >
                                  <i className="fa fa-search"></i> Filter
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <br></br>
                  </div>
                  {/*end filter*/}
                  <div className="table-responsive widget-content">
                    {displayLoading}
                    {displayError}
                    {displayBorrowers}
                  </div>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { borrowers } = state;
  return { borrowers };
}

const actionCreators = (dispatch, props) => {
  return {
    getAllBorrowers: (
      pageNumber,
      id_no,
      phone_no,
      email,
      name,
      status,
      start_create_date,
      end_create_date
    ) => {
      dispatch(
        borrowerActions.getAllBorrowers(
          pageNumber,
          id_no,
          phone_no,
          email,
          name,
          status,
          start_create_date,
          end_create_date
        )
      );
    },
  };
};

const connectedBorrowersList = connect(mapState, actionCreators)(BorrowersList);
export { connectedBorrowersList as BorrowersList };
