import React from 'react';
import { connect } from 'react-redux';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import { timeService } from '../../_helpers/time.ago';
import { Link } from 'react-router-dom';
import UpdatePassword from './UpdatePassword';

const ViewProfile = (props) => {

    const { userProfile } = props;
    
    return(
        <div className="col-xl-12 col-lg-12 col-md-12 layout-spacing admin-profile">
            <Tabs>
                <TabList>
                <Tab>Profile</Tab>
                <Tab>Change Password</Tab>
                </TabList>
                <TabPanels style={{ paddingTop: 15 }}>
                    <TabPanel>
                    <div className="card">
                        <div className="card-body">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className='row-header'>Name:</td>
                                        <td className='row-data'>{`${userProfile.first_name} ${userProfile.last_name}`}</td>
                                    </tr>
                                    <tr>
                                        <td className='row-header'>Email:</td>
                                        <td className='row-data'>{userProfile.email}</td>
                                    </tr>
                                    <tr>
                                        <td className='row-header'>Contact:</td>
                                        <td className='row-data'>{userProfile.phone_number}</td>
                                    </tr>
                                    <tr>
                                        <td className='row-header'>User name:</td>
                                        <td className='row-data'>{userProfile.user_name}</td>
                                    </tr>
                                    <tr>
                                        <td className='row-header'>Department:</td>
                                        <td className='row-data'>{userProfile.department}</td>
                                    </tr>
                                    <tr>
                                        <td className='row-header'>Member since:</td>
                                        <td className='row-data'>{timeService.timeAgo(userProfile.created_at)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </TabPanel>
                    <TabPanel>
                        <div className='admin-password-update'>
                           <UpdatePassword />
                        </div>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    );
};

function mapStateToProps(state){
    return {
        userProfile: state.authentication.user.data
    }
};

export default connect(mapStateToProps)(ViewProfile);