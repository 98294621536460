import { combineReducers } from "redux";

import { authentication } from "./authenication.reducer";
import { alert } from "./alert.reducer";
import { users, newuser, isvalid } from "./users.reducer";
import { borrowers, getborrower, newcustomer, updatecustomer, sendotp } from "./borrowers.reducer";
import { loans, borrowings, repayments } from "./loans.reducer";
import { activeloans } from "./active_loan.reducer";
import {
  dashboard,
  dashboardMonthlyLoans,
  dashboardMonthlyCollections,
  openloansData,
  requestedloans,
  releasedloans,
  fullpaidloans,
  partialpaidloans,
  pendingloans,
  overdueloans,
} from "./dashboard.reducer";

const rootReducer = combineReducers({
  authentication,
  alert,
  users,
  newuser,
  borrowers,
  getborrower,
  newcustomer,
  updatecustomer,
  sendotp,
  loans,
  borrowings,
  repayments,
  activeloans,
  dashboard,
  dashboardMonthlyLoans,
  dashboardMonthlyCollections,
  openloansData,
  requestedloans,
  releasedloans,
  fullpaidloans,
  partialpaidloans,
  pendingloans,
  overdueloans,
  isvalid,
});

export default rootReducer;
