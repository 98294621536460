import { borrowerConstants } from "../_constants";

import { history } from "../_helpers";
import { borrowerService } from "../_services";

export const borrowerActions = {
  getAllBorrowers,
  getBorrowerById,
  createCustomer,
  updateCustomer,
  sendOtp
};

/**
 *
 * @param {*} pageNumber
 * @param {*} id_no
 * @param {*} phone_no
 * @param {*} email
 * @param {*} name
 * @param {*} status
 * @param {*} start_create_date
 * @param {*} end_create_date
 */
function getAllBorrowers(
  pageNumber,
  id_no,
  phone_no,
  email,
  name,
  status,
  start_create_date,
  end_create_date
) {
  return (dispatch) => {
    dispatch(request());

    borrowerService
      .getAllBorrowers(
        pageNumber,
        id_no,
        phone_no,
        email,
        name,
        status,
        start_create_date,
        end_create_date
      )
      .then(
        (borrowers) => dispatch(success(borrowers)),
        (error) => dispatch(failure(error.toString()))
      );
  };
}

function request() {
  return {
    type: borrowerConstants.GETALL_BORROWERS_REQUEST,
  };
}

function success(borrowers) {
  return {
    type: borrowerConstants.GETALL_BORROWERS_SUCCESS,
    borrowers,
  };
}

function failure(error) {
  return {
    type: borrowerConstants.GETALL_BORROWERS_FAILURE,
    error,
  };
}

/**
 *
 * @param {*} id
 * @param {*} loan_status
 * @param {*} repayment
 * @param {*} start_loan_requested_date
 * @param {*} end_loan_requested_date
 * @param {*} mpesa_code
 * @param {*} transaction_code
 */

function getBorrowerById(
  id,
  loan_status,
  repayment,
  start_loan_requested_date,
  end_loan_requested_date,
  mpesa_code,
  transaction_code
) {
  return (dispatch) => {
    dispatch(
      request(
        id,
        loan_status,
        repayment,
        start_loan_requested_date,
        end_loan_requested_date,
        mpesa_code,
        transaction_code
      )
    );
    borrowerService
      .getBorrowerById(
        id,
        loan_status,
        repayment,
        start_loan_requested_date,
        end_loan_requested_date,
        mpesa_code,
        transaction_code
      )
      .then(
        (borrower) => dispatch(success(borrower)),
        (error) => dispatch(failure(error.toString()))
      );
  };

  /**
   *
   * @param {*} id
   * @param {*} loan_status
   * @param {*} repayment
   * @param {*} start_loan_requested_date
   * @param {*} end_loan_requested_date
   * @param {*} mpesa_code
   * @param {*} transaction_code
   */
  function request(
    id,
    loan_status,
    repayment,
    start_loan_requested_date,
    end_loan_requested_date,
    mpesa_code,
    transaction_code
  ) {
    return {
      type: borrowerConstants.GETBYID_BORROWER_REQUEST,
      id,
      loan_status,
      repayment,
      start_loan_requested_date,
      end_loan_requested_date,
      mpesa_code,
      transaction_code,
    };
  }
  function success(borrower) {
    return { type: borrowerConstants.GETBYID_BORROWER_SUCCESS, borrower };
  }
  function failure(id, error) {
    return { type: borrowerConstants.GETBYID_BORROWER_FAILURE, error };
  }
}

/**
 *
 * @param {*} first_name
 * @param {*} last_name
 * @param {*} phone_no
 * @param {*} id_no
 * @param {*} dob
 * @param {*} gender
 * @param {*} email
 * @param {*} alternative_phone_no
 * @param {*} credit_score
 */
function createCustomer(
  first_name,
  last_name,
  phone_no,
  id_no,
  dob,
  gender,
  email,
  alternative_phone_no,
  credit_score
) {
  return (dispatch) => {
    dispatch(
      requestNewCustomer(
        first_name,
        last_name,
        phone_no,
        id_no,
        dob,
        gender,
        email,
        alternative_phone_no,
        credit_score
      )
    );

    borrowerService
      .createCustomer(
        first_name,
        last_name,
        phone_no,
        id_no,
        dob,
        gender,
        email,
        alternative_phone_no,
        credit_score
      )
      .then(
        (newcustomer) => dispatch(newCustomerSuccess(newcustomer)),
        (error) => dispatch(newCustomerFailure(error.toString()))
      );
  };
}

function updateCustomer(
  customer_id,
  loan_limit,
  otp
) {
  return (dispatch) => {
    dispatch(
      updateCurrentCustomer(
        customer_id,
        loan_limit,
        otp
      )
    );

    borrowerService
      .updateCustomer(
        customer_id,
        loan_limit,
        otp
      )
      .then(
        (customer) => dispatch(updateCurrentCustomerSuccess(customer)),
        (error) => dispatch(updateCurrentCustomerFailure(error.toString()))
      );
  };
}

function sendOtp(){
  return (dispatch) => {
    dispatch(
      sendOtpRequest()
    );

      borrowerService.sendOtp()
      .then(
        (response) => dispatch(sendOtpSuccess(response)),
        (error) => dispatch(sendOtpFailure(error.toString()))
      );
  }
};



function requestNewCustomer(
  first_name,
  last_name,
  phone_no,
  id_no,
  dob,
  gender,
  email,
  alternative_phone_no,
  credit_score
) {
  return {
    type: borrowerConstants.NEW_CUSTOMER_REQUEST,
  };
}

function newCustomerSuccess(newcustomer) {
  return {
    type: borrowerConstants.NEW_CUSTOMER_SUCCESS,
    newcustomer,
  };
}

function newCustomerFailure(error) {
  return {
    type: borrowerConstants.NEW_CUSTOMER_FAILURE,
    error,
  };
}

function updateCurrentCustomer(
  customer_id,
  loan_limit,
  otp
) {
  return {
    type: borrowerConstants.UPDATE_CUSTOMER_REQUEST,
  };
}

function updateCurrentCustomerSuccess(customer) {
  return {
    type: borrowerConstants.UPDATE_CUSTOMER_SUCCESS,
    customer,
  };
}

function updateCurrentCustomerFailure(error) {
  return {
    type: borrowerConstants.UPDATE_CUSTOMER_FAILURE,
    error,
  };
}

function sendOtpRequest(){
  return {
    type: borrowerConstants.SEND_OTP_REQUEST
  }
};

function sendOtpSuccess(response){
  return {
    type: borrowerConstants.SEND_OTP_SUCCESS,
    response
  }
};

function sendOtpFailure(error){
  return {
    type: borrowerConstants.SEND_OTP_FAILURE,
    error
  }
};