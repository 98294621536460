import React, { useState} from 'react';
import { connect, useDispatch } from 'react-redux';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import OverlayLoader from 'react-overlay-loading/lib/OverlayLoader';
import Joi from "joi-browser";
import { userService } from '../../_services';
import { alertActions } from '../../_actions/alert.actions';

const UpdatePassword = (props) => {

    const { user_id } = props;
    let dispatch = useDispatch();
    const [currentpassword, setCurrent] = useState('');
    const [newpassword, setNew] = useState('');
    const [confirm, setConfirm] = useState('');
    const [errors, setErrors] = useState({
        currentpassword: '',
        newpassword: '',
        confirm: '',
        response: ''
    });
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);

    const schema = {
        currentpassword: Joi.string().min(8)
          .required()
          .error(errors => {
            errors.forEach(err => {
              switch (err.type) {
                case 'any.empty':
                    err.message = "Current password is required!"
                    break;
                  default:
                    err.message = "Should a have minimum of 8 characters!";
                    break;
              }
            });
            return errors;
          }),
        newpassword: Joi.string().min(8)
          .required()
          .error(errors => {
            errors.forEach(err => {
              switch (err.type) {
                case 'any.empty':
                    err.message = "New password is required!";
                    break;
                default:
                  err.message = "Should have a minimum of 8 characters!"
                  break;
              }
            });
            return errors;
          }),
          confirm: Joi.string().min(8)
          .required()
          .error(errors => {
            errors.forEach(err => {
              switch (err.type) {
                case 'any.empty':
                    err.message = "Confirmation is required!"
                    break;
                  default:
                    err.message = "Should have a minimum of 8 characters!";
                    break;
              }
            });
            return errors;
          })
      };
    
      const validate = () => {
        const options = { abortEarly: false };
        const form = {
          currentpassword: currentpassword,
          newpassword: newpassword,
          confirm: confirm
        };
        const { error } = Joi.validate(form, schema, options);
        if (!error) return null;
    
        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message;
        return errors;
      };

    function handleSubmit(event){
        event.preventDefault();
        const error = validate();
        if (!error) {
            setLoader(true);
            userService.passwordUpdate({ current_password: currentpassword, new_password: newpassword, new_confirm_password: confirm })
            .then(res => {
                console.log(res)
                if(res.success){
                    setMessage(res.message)
                    setCurrent('');
                    setNew('');
                    setConfirm('');
                }
                else{
                   setErrors(res.message);
                }
            })
            .catch(error => {
                dispatch(alertActions.error(error.message));
            })
            .finally(() => {
                setLoader(false);
            })
        }
        else {
           setErrors(error)
        }
    }
    console.log(errors)
    return(
            <div className="card">
                <div className="card-body">
                <OverlayLoader color={"#292a6a"} loader="ScaleLoader" text="Updating password..." active={loader} backgroundColor={"#fff"} opacity=".5"></OverlayLoader>
                    <div className='row'>
                        <div className='col-md-6'>
                            <form className="text-left accountWrapper" onSubmit={(event) => handleSubmit(event)}>
                            <div className="form">
                                <div id="password-field" className="field-wrapper input mb-4">
                                <div className='input-group'>
                                    <label>Current password:</label>
                                    <div>
                                        <input name="current" type="password" onChange={(event) => setCurrent(event.target.value)} value={currentpassword} className="form-control mb-2" error={errors.currentpassword}
                                            helpertext={
                                            errors.currentpassword
                                            }
                                        />
                                        <help>{errors.currentpassword}</help>
                                    </div>
                                </div>
                                </div>
                                <div id="password-field" className="field-wrapper input mb-4">
                                <div className='input-group'>
                                    <label>New password:</label>
                                    <div>
                                        <input name="new" type="password" value={newpassword} onChange={(event) => setNew(event.target.value)} className="form-control mb-2" error={errors.newpassword}
                                            helpertext={
                                            errors.newpassword
                                            }
                                        />
                                        <help> {errors.newpassword}</help>
                                    </div>
                                </div>
                                </div>
                                <div id="password-field" className="field-wrapper input mb-3">
                                <div className='input-group'>
                                    <label>Confirm password:</label>
                                    <div>
                                        <input name="confirm" type="password" value={confirm} onChange={(event) => setConfirm(event.target.value)} className="form-control mb-2" error={errors.confirm}
                                            helpertext={
                                            errors.confirm
                                            }
                                        />
                                        <help> {errors.confirm}</help>
                                    </div>
                                </div>
                                </div>
                                <div className="justify-content-between">
                                <div className="field-wrapper">
                                    <button type="submit" className="btn btn-primary btn-block change-btn" disabled={loader}>UPDATE</button>
                                </div>
                                </div>

                            </div>
                            </form>
                        </div>
                        <div className='col-md-5'></div>
                    </div>
                    <div className='row mt-4'>
                        <div className='col-md-6'>
                        {
                                error ? 
                                  <div className="alert alert-danger alert-dismissible">
                                      {error}
                                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  :
                                  ''
                            }
                             {
                                message ? 
                                  <div className="alert alert-success alert-dismissible">
                                      {message}
                                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                                          <span aria-hidden="true">&times;</span>
                                      </button>
                                  </div>
                                  :
                                  ''
                            }
                        </div>
                    </div>
                </div>
            </div>

    );
};

function mapStateToProps(state){
    return {
        user_id: state.authentication.user.data.id
    }
};

export default connect(mapStateToProps)(UpdatePassword);