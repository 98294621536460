import React, { Component } from "react";
import { connect } from "react-redux";
import ShimmerEffect from "../../../_components/ShimmerEffect";
import { loanActions } from "../../../_actions";
import Avatar from "react-avatar";
import LoanSummary from "./LoanSummary";

class LoanHistoryBorrowings extends Component {
  componentDidMount() {
    this.props.getBorrowingHistory(this.props.loan);
  }

  _displayLoanData = () => {
    const { borrowings } = this.props;

    if (borrowings.items.data.errors == null) {
      if (borrowings.items.data.borrowings != "undefined") {
        //let loansArray = loanhistory.items.data.borrowings;

        let loansArray = borrowings.items.data.borrowings;

        let parentArray = borrowings.items.data;

        if (loansArray != "undefined") {
          return loansArray.map((loan, key) => {
            return (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{parentArray["transaction_code"]}</td>
                <td>{Number(loan.amount_with_interest).toFixed(2)}</td>
                <td>{loan.created_at}</td>
              </tr>
            );
          });
        } else {
          return (
            <React.Fragment>
              <div className="alert alert info">No borrowing records found</div>
            </React.Fragment>
          );
        }
      }
    }
  };

  render() {
    const { borrowings } = this.props;

    const displayLoading = borrowings.loading && (
      <React.Fragment>
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Loan ID</th>
              <th>Loan Amount</th>
              <th>Borrowing Date</th>
            </tr>
          </thead>
          <tbody>
            <ShimmerEffect columns={5} rows={4} />
          </tbody>
        </table>
      </React.Fragment>
    );
    const displayError = borrowings.error && (
      <div className="col-md-12 offset-md-1 mt-4">
        <span className="text-danger">ERROR: {borrowings.error}</span>
      </div>
    );

    const displayLoans = borrowings.items && (
      <React.Fragment>
        <LoanSummary summary={borrowings.items.data} />
        <h2 className="pg-title2 text-primary">
          Borrowing History{" "}
          <span className="filter">
            <i className="fa fa-angle-double-down"></i>
          </span>
        </h2>
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Loan ID</th>
              <th>Loan Amount</th>
              <th>Borrowing Date</th>
            </tr>
          </thead>
          <tbody>{this._displayLoanData()}</tbody>
        </table>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        {displayLoading}
        {displayError}
        {displayLoans}
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { borrowings } = state;
  return { borrowings };
}

const actionCreators = (dispatch, props) => {
  return {
    getBorrowingHistory: (id) => {
      dispatch(loanActions.getBorrowingHistory(id));
    },
  };
};

const connectedLoanHistoryBorrowings = connect(
  mapState,
  actionCreators
)(LoanHistoryBorrowings);

export { connectedLoanHistoryBorrowings as LoanHistoryBorrowings };
