import { loanConstants } from "../_constants";

/**
 *
 * @param {*} state
 * @param {*} action
 */
export function loans(state = {}, action) {
  switch (action.type) {
    case loanConstants.GETALL_LOANS_REQUEST:
      return {
        loading: true,
      };
    case loanConstants.GETALL_LOANS_SUCCESS:
      return {
        items: action.loans,
        total: action.loans.data.total,
        per_page: action.loans.data.per_page,
        current_page: action.loans.data.current_page,
        last_page: action.loans.data.last_page,
      };
    case loanConstants.GETALL_LOANS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

/**
 *
 * @param {*} state
 * @param {*} action
 */
export function loanhistory(state = {}, action) {
  switch (action.type) {
    case loanConstants.LOAN_HISTORY_REQUEST:
      return {
        loading: true,
      };
    case loanConstants.LOAN_HISTORY_SUCCESS:
      return {
        items: action.loanhistory,
      };
    case loanConstants.LOAN_HISTORY_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

/**
 *
 * @param {*} state
 * @param {*} action
 */

export function borrowings(state = {}, action) {
  switch (action.type) {
    case loanConstants.GET_BORROWINGS_HISTORY_REQUEST:
      return {
        loading: true,
      };
    case loanConstants.GET_BORROWING_HISTORY_SUCCESS:
      return {
        items: action.borrowings,
      };
    case loanConstants.GET_BORROWINGS_HISTORY_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

/**
 *
 * @param {*} state
 * @param {*} action
 */

export function repayments(state = {}, action) {
  switch (action.type) {
    case loanConstants.GET_REPAYMENTS_HISTORY_REQUEST:
      return {
        loading: true,
      };
    case loanConstants.GET_REPAYMENTS_HISTORY_SUCCESS:
      return {
        items: action.repayments,
      };
    case loanConstants.GET_REPAYMENTS_HISTORY_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
