import React, { Component } from "react";

export class Breadcrump extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="sub-header-container">
          <header className="header navbar navbar-expand-sm">
            <ul className="navbar-nav flex-row">
              <li>
                <div className="page-header">
                  <nav className="breadcrumb-one" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <a href="#">Zaracash</a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <span>Admin</span>
                      </li>
                    </ol>
                  </nav>
                </div>
              </li>
            </ul>
          </header>
        </div>
      </React.Fragment>
    );
  }
}
