import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { userActions } from "../../_actions";

import Joi from "joi-browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OverlayLoader from "react-overlay-loading/lib/OverlayLoader";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    //reset login status
    this.props.logout();

    this.state = {
      email: "",
      password: "",
      remember: false,
      showpass: false,
      error: {},
      disable: false,
      loader: false,
      submitted: false
    };
  }

  schema = {
    email: Joi.string()
      .email({ minDomainAtoms: 2 })
      .required()
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            case "string.email":
              err.message = "Enter a valid email";
              break;
            default:
              err.message = "Email cannot be empty";
              break;
          }
        });
        return errors;
      }),
    password: Joi.string()
      .required()
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            default:
              err.message = "Enter a valid password";
              break;
          }
        });
        return errors;
      })
  };

  changeHandler = event => {
    const error = { ...this.state.error };
    const errorMassage = this.validationProperty(event);
    if (errorMassage) {
      error[event.target.name] = errorMassage;
    } else {
      delete error[event.target.name];
    }
    this.setState({
      [event.target.name]: event.target.value,
      error
    });
  };

  validationProperty = event => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const form = {
      password: this.state.password,
      email: this.state.email
    };
    const { error } = Joi.validate(form, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  submitLogin = e => {
    e.preventDefault();

    const error = this.validate();

    if (!error) {
      this._onLogin();
    } else {
      this.setState({
        error: error || {}
      });
    }
  };

  _onLogin = () => {
    this.setState({
      loader: !this.state.loader,
      disable: !this.state.disable,
      submitted: true
    });
    const { email, password } = this.state;

    if (email && password) {
      this.props.login(email, password);
    }
  };

  render() {
    const { loggingIn } = this.props;
    const { email, password, submitted } = this.state;
    return (
      <React.Fragment>
        <div className="form">
          <div className="form-container">
            <div className="form-form">
              <div className="form-form-wrap">
                <div className="form-container">
                  <div className="form-content">
                    <OverlayLoader
                      color={"#292a6a"} // default is white
                      loader="ScaleLoader" // check below for more loaders
                      text="Login in ... Please wait!"
                      active={this.state.loader}
                      backgroundColor={"#fff"} // default is black
                      opacity=".5" // default is .9
                    ></OverlayLoader>
                    <h1 className="">
                      Log In to{" "}
                      <Link to="/">
                        <span className="brand-name">Zaracash</span>
                      </Link>
                    </h1>
                    <form
                      className="text-left accountWrapper"
                      onSubmit={this.submitLogin}
                    >
                      <div className="form">
                        <div
                          id="username-field"
                          className="field-wrapper input"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-user"
                          >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                          <input
                            name="email"
                            type="text"
                            onChange={this.changeHandler}
                            value={this.state.email}
                            className="form-control"
                            placeholder="Your Zaracash Email"
                            error={this.state.error.email && true}
                            helpertext={
                              this.state.error.email && this.state.error.email
                            }
                          />
                          <help>
                            {this.state.error.email && this.state.error.email}
                          </help>
                        </div>

                        <div
                          id="password-field"
                          className="field-wrapper input mb-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-lock"
                          >
                            <rect
                              x="3"
                              y="11"
                              width="18"
                              height="11"
                              rx="2"
                              ry="2"
                            ></rect>
                            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                          </svg>
                          <input
                            name="password"
                            onChange={this.changeHandler}
                            value={this.state.password}
                            type="password"
                            className="form-control"
                            placeholder="Your Zaracash Password"
                            name="password"
                            onChange={this.changeHandler}
                            value={this.state.password}
                          />
                          <help>
                            {this.state.error.password &&
                              this.state.error.password}
                          </help>
                        </div>
                        <div className="justify-content-between">
                          <div className="field-wrapper">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block"
                              disabled={this.state.disable}
                            >
                              LOGIN
                            </button>
                          </div>
                        </div>

                        <div className="field-wrapper">
                          <a
                            href="auth_pass_recovery.html"
                            className="forgot-pass-link"
                          >
                            Forgot Password?
                          </a>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="form-image">
              <div className="l-image"></div>
            </div>
          </div>
        </div>

        <ToastContainer position="top-right" />
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout
};

const connectedLogin = connect(mapState, actionCreators)(LoginPage);

export { connectedLogin as LoginPage };
