import React, { Component } from "react";
import { moneyFormat } from "../../../_components/numberformat";

export default class LoanSummary extends Component {
  render() {
    const { summary } = this.props;

    let amout_repaid = 0;
    if (summary["amount_repaid"] === null) {
      amout_repaid = amout_repaid;
    } else {
      amout_repaid = summary["amount_repaid"];
    }
    return (
      <div>
        <h2 className="pg-title2 text-primary">
          Loans Summary{" "}
          <span className="filter">
            <i className="fa fa-angle-double-down"></i>
          </span>
        </h2>
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th className="text-center">Loan ID</th>
              <th className="text-center">Principal</th>
              <th className="text-center">Loan Amount</th>

              <th className="text-center">Loan Date</th>
              <th className="text-center">Maturity</th>
              <th className="text-center">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">{summary["transaction_code"]}</td>
              <td className="text-center">{moneyFormat(summary["amount"])}</td>
              <td className="text-center">
                {moneyFormat(summary["to_repay_amount"])}
              </td>

              <td className="text-center">{summary["created_at"]}</td>
              <td className="text-center">
                {summary["proposed_repayment_time"]}
              </td>
              <td className="text-center">{summary["status"]}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}
