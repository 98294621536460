import { userConstants } from "../_constants";

/*export function users(state = {}, action) {
  return state;
}*/

/**
 *
 * @param {*} state
 * @param {*} action
 */
export function newuser(state = {}, action) {
  switch (action.type) {
    case userConstants.NEW_USER_REQUEST:
      return {
        loading: true,
      };
    case userConstants.NEW_USER_SUCCESS:
      return {
        item: action.newuser,
      };
    case userConstants.NEW_USER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
/**
 *
 * @param {*} state
 * @param {*} action
 */
export function users(state = {}, action) {
  switch (action.type) {
    case userConstants.GETALL_USERS_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GETALL_USERS_SUCCESS:
      return {
        items: action.users,
      };
    case userConstants.GETALL_USERS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
/**
 *
 * @param {*} state
 * @param {*} action
 */
export function isvalid(state = {}, action) {
  switch (action.type) {
    case userConstants.USER_VALID_REQUEST:
      return {
        loading: true,
      };
    case userConstants.USER_VALID_SUCCESS:
      return {
        items: action.isvalid,
      };
    case userConstants.USER_VALID_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
