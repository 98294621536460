import React, { Component } from "react";
import { connect } from "react-redux";
import ShimmerEffect from "../../../_components/ShimmerEffect";
import { loanActions } from "../../../_actions";
import Avatar from "react-avatar";
import LoanSummary from "./LoanSummary";

class LoanHistoryRepayments extends Component {
  componentDidMount() {
    this.props.getRepaymentsHistory(this.props.loan);
  }

  _displayLoanData = () => {
    const { repayments } = this.props;

    if (repayments.items.data.errors == null) {
      if (repayments.items.data.repayments != "undefined") {
        //let loansArray = loanhistory.items.data.repayments;

        let loansArray = repayments.items.data.repayments;

        let parentArray = repayments.items.data;

        if (loansArray != "undefined") {
          return loansArray.map((loan, key) => {
            return (
              <tr key={key}>
                <td>{key + 1}</td>
                <td>{parentArray["transaction_code"]}</td>
                <td>{Number(loan.amount).toFixed(2)}</td>
                <td>{loan.created_at}</td>
              </tr>
            );
          });
        } else {
          return (
            <React.Fragment>
              <div className="alert alert info">
                No repayments records found
              </div>
            </React.Fragment>
          );
        }
      }
    }
  };

  render() {
    const { repayments } = this.props;

    console.log("REPAYMENTS ARE", repayments);

    const displayLoading = repayments.loading && (
      <React.Fragment>
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Loan ID</th>
              <th>Paid Amount</th>
              <th>Rapayment Date</th>
            </tr>
          </thead>
          <tbody>
            <ShimmerEffect columns={5} rows={4} />
          </tbody>
        </table>
      </React.Fragment>
    );
    const displayError = repayments.error && (
      <div className="col-md-12 offset-md-1 mt-4">
        <span className="text-danger">ERROR: {repayments.error}</span>
      </div>
    );

    const displayLoans = repayments.items && (
      <React.Fragment>
        <LoanSummary summary={repayments.items.data} />
        <h2 className="pg-title2 text-primary">
          Repayment History{" "}
          <span className="filter">
            <i className="fa fa-angle-double-down"></i>
          </span>
        </h2>
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Loan ID</th>
              <th>Paid Amount</th>
              <th>Repayment Date</th>
            </tr>
          </thead>
          <tbody>{this._displayLoanData()}</tbody>
        </table>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        {displayLoading}
        {displayError}
        {displayLoans}
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { repayments } = state;
  return { repayments };
}

const actionCreators = (dispatch, props) => {
  return {
    getRepaymentsHistory: (id) => {
      dispatch(loanActions.getRepaymentsHistory(id));
    },
  };
};

const connectedLoanHistoryRepayments = connect(
  mapState,
  actionCreators
)(LoanHistoryRepayments);

export { connectedLoanHistoryRepayments as LoanHistoryRepayments };
