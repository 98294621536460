import { API_URL } from "../_config/env";
import { authHeader } from "../_helpers";
import { userService } from "./user.service";

export const dashboardService = {
  getDashbordTotals,
  getMonthlyLoansChart,
  getMonthlyLoansCollections,
  displayOpenLoansData,
  getRequestedLoans,
  getReleasedLoans,
  getFullPaidLoans,
  getPartiallyPaidLoans,
  getPendingLoans,
  getOverdueLoans,
};

function getDashbordTotals() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/dashboard-totals`, requestOptions).then(
    handleResponse
  );
}

function getMonthlyLoansChart() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/loans_release_by_month`, requestOptions).then(
    handleResponse
  );
}

function getMonthlyLoansCollections() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/loans_collection_by_month`, requestOptions).then(
    handleResponse
  );
}

//More Details
function displayOpenLoansData(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/active_loans?page=${pageNumber}&id_no=${id_no}&phone_no=${phone_no}&mpesa_code=${mpesa_code}&repayment=${repayment}&status=${status}&start_repayment_date=${start_repayment_date}&end_repayment_date=${end_repayment_date}`,
    requestOptions
  ).then(handleResponse);
}

//Requested Loans
function getRequestedLoans(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/requested_loans?page=${pageNumber}&id_no=${id_no}&phone_no=${phone_no}&mpesa_code=${mpesa_code}&repayment=${repayment}&status=${status}&start_repayment_date=${start_repayment_date}&end_repayment_date=${end_repayment_date}`,
    requestOptions
  ).then(handleResponse);
}

//Released Loans
function getReleasedLoans(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/released_loans?page=${pageNumber}&id_no=${id_no}&phone_no=${phone_no}&mpesa_code=${mpesa_code}&repayment=${repayment}&status=${status}&start_repayment_date=${start_repayment_date}&end_repayment_date=${end_repayment_date}`,
    requestOptions
  ).then(handleResponse);
}

//Full Paid Loans
function getFullPaidLoans(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/fully_paid_loans?page=${pageNumber}&id_no=${id_no}&phone_no=${phone_no}&mpesa_code=${mpesa_code}&repayment=${repayment}&status=${status}&start_repayment_date=${start_repayment_date}&end_repayment_date=${end_repayment_date}`,
    requestOptions
  ).then(handleResponse);
}

//Pending Loans
function getPendingLoans(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/loan?page=${pageNumber}&id_no=${id_no}&phone_no=${phone_no}&mpesa_code=${mpesa_code}&repayment=${repayment}&status=${"overdue"}&start_repayment_date=${start_repayment_date}&end_repayment_date=${end_repayment_date}`,
    requestOptions
  ).then(handleResponse);
}

//Over due loans
function getOverdueLoans(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/loan?page=${pageNumber}&id_no=${id_no}&phone_no=${phone_no}&mpesa_code=${mpesa_code}&repayment=${repayment}&status=${"overdue"}&start_repayment_date=${start_repayment_date}&end_repayment_date=${end_repayment_date}`,
    requestOptions
  ).then(handleResponse);
}
//End Overdue Loans

//Partially Paid Loans
function getPartiallyPaidLoans(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/partially_paid_loans?page=${pageNumber}&id_no=${id_no}&phone_no=${phone_no}&mpesa_code=${mpesa_code}&repayment=${repayment}&status=${status}&start_repayment_date=${start_repayment_date}&end_repayment_date=${end_repayment_date}`,
    requestOptions
  ).then(handleResponse);
}

//End More Details

function logout() {
  userService.logout();
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        //auto logout
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
