import React, { Component } from "react";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { dashboardActions } from "../../../_actions";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalBarSeries,
  Hint
} from "react-vis";

class MonthlyLoans extends Component {
  componentDidMount() {
    this.props.getMonthlyRelease();
  }
  render() {
    const { dashboardMonthlyLoans } = this.props;

    const displayLoading = dashboardMonthlyLoans.loading && (
      <div className="card-body">
        <div className="col-md-4 offset-md-5 mt-2 mb-2">
          <Loader type="TailSpin" color="#2da3e0" height={30} width={30} />
        </div>
      </div>
    );
    const displayError = dashboardMonthlyLoans.error && (
      <div className="col-md-12 offset-md-1 mt-4">
        <span className="text-danger">
          ERROR: {dashboardMonthlyLoans.error}
        </span>
      </div>
    );

    const displayMonthlyRelease = dashboardMonthlyLoans.monthlyrelease && (
      <div className="card-body">
        <XYPlot
          height={300}
          width={window.innerWidth * 0.38}
          margin={{ left: 50 }}
          xType="ordinal"
        >
          <HorizontalGridLines />
          <XAxis
            title="Months"
            style={{
              line: { stroke: "#ADDDE1" },
              ticks: { stroke: "#ADDDE1" },
              text: { stroke: "none", fill: "#6b6b76", fontWeight: 400 }
            }}
          />
          <YAxis title="Amounts in Ksh" />

          <VerticalBarSeries data={dashboardMonthlyLoans.monthlyrelease.data} />
          <XAxis />
          <YAxis />
        </XYPlot>
      </div>
    );

    return (
      <React.Fragment>
        <div className="col-xl-6 col-md-6 col-lg-6 col-sm-6">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title card-title-dashboard">
                Loans Released - Monthly(2020)
              </h3>
            </div>

            {displayLoading}
            {displayError}
            {displayMonthlyRelease}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { dashboardMonthlyLoans } = state;
  return { dashboardMonthlyLoans };
}

const actionCreators = {
  getMonthlyRelease: dashboardActions.getDashboardMonthlyRelease
};

const connectedMonthlyLoans = connect(mapState, actionCreators)(MonthlyLoans);
export { connectedMonthlyLoans as MonthlyLoans };
