import React, { Component } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: 50,
    display: "inline-block",
  },
});

export default function ShimmerEffect(props) {
  const classes = useStyles();

  const columns = [];
  for (let i = 0; i < props.columns; i++) {
    columns.push(
      <td key={i}>
        <Skeleton animation="wave" height={10} />
      </td>
    );
  }

  const rows = [];

  for (let j = 0; j < props.rows; j++) {
    rows.push(<tr key={j}>{columns}</tr>);
  }

  return rows;
}
