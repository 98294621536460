import React, { Component } from "react";

import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import { LoanHistoryBorrowings, LoanHistoryRepayments } from "./loanhistory";

class LoanHistory extends Component {
  render() {
    const { showModal, loan } = this.props;

    if (showModal === false) {
      return "";
    } else {
      return (
        <React.Fragment>
          <div id={"modal-" + loan} className="modal col-md-12">
            <div
              className="modal-dialog modal-lg modal-dialog-centered"
              role="document"
            >
              <div className="modal-content col-md-12">
                <div className="modal-header">
                  <div className="col-md-12">
                    <h5 className="modal-title" id={"modal-" + loan + "Label"}>
                      {this.props.name} Loan History
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() =>
                        this.props.onModalClose({
                          loanxid: "",
                          showModal: false,
                        })
                      }
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                </div>

                <div className="modal-body">
                  <Tabs>
                    <TabList>
                      <Tab>Borrowing History</Tab>
                      <Tab>Repayments History</Tab>
                    </TabList>

                    <TabPanels style={{ paddingTop: 15 }}>
                      <TabPanel>
                        <div className="table-responsive widget-content col-md-12">
                          <LoanHistoryBorrowings loan={loan} />
                        </div>
                      </TabPanel>

                      <TabPanel>
                        <div className="table-responsive widget-content col-md-12">
                          <LoanHistoryRepayments loan={loan} />
                        </div>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default LoanHistory;
