import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class SessionModal extends Component {
  render() {
    const { logoutModal } = this.props;

    console.log("LOGOUT MODAL IS ", logoutModal);

    if (logoutModal === false) {
      return "";
    } else {
      return (
        <React.Fragment>
          {/*<div id="bytecity" className="modal col-md-12">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content col-md-10">
                <div className="modal-body row">
                  <div className="col-md-12">
                  <h2 className="modal-title center" id="bytecityLabel">
                    Session Expired
                  </h2>
                  <p className="center">
                    To keep your information safe, we logged you out
                  </p>
                  </div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <Link
                      to="/login"
                      class="btn btn-block btn-sm btn-rounded btn-info"
                    >
                      Login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
      </div>*/}
        </React.Fragment>
      );
    }
  }
}
