import React from "react";
import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { dashboardActions } from "../../../_actions";
import { numberFormat, moneyFormat } from "../../../_components/numberformat";

class DashboardTotals extends React.Component {
  componentDidMount() {
    this.props.getDashboardTotals();
  }

  render() {
    const { dashboard } = this.props;

    const displayLoading = dashboard.loading && (
      <div className="row dash-totals">
        <div className="col-xl-4 col-md-12 col-lg-12">
          <div className="card card card-bordered text-light is-dark h-100">
            <div className="card-body">
              <div className="ibox teams">
                <div className="ibox-content teams">
                  <div className="teams-board-details">
                    <div className="nk-wg7-stats">
                      <div className="nk-wg7-title text-center">
                        Open Loans Ksh
                      </div>
                      <div className="number-lg amount text-center">
                        <Loader
                          type="TailSpin"
                          color="#fff"
                          height={10}
                          width={10}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="nk-wg7-stats-group">
                    <div className="nk-wg7-stats w-50 text-center">
                      <div className="nk-wg7-title">Closed Interest</div>
                      <div className="number-av">
                        <Loader
                          type="TailSpin"
                          color="#fff"
                          height={10}
                          width={10}
                        />
                      </div>
                    </div>
                    <div className="nk-wg7-stats w-50 text-center">
                      <div className="nk-wg7-title">Open Interest</div>
                      <div className="number-av">
                        <Loader
                          type="TailSpin"
                          color="#fff"
                          height={10}
                          width={10}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-12 col-sm-4">
                      <div className="teams-rank nk-wg7-title mt-2 text-blue">
                        Total Open Loans
                      </div>{" "}
                      <span className="number-font1 text-center">
                        <Loader
                          type="TailSpin"
                          color="#fff"
                          height={10}
                          width={10}
                        />
                      </span>{" "}
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="teams-rank nk-wg7-title mt-2 text-blue">
                        Principal Requested
                      </div>{" "}
                      <span className="number-font1 text-center">
                        <Loader
                          type="TailSpin"
                          color="#fff"
                          height={10}
                          width={10}
                        />
                      </span>{" "}
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="teams-rank nk-wg7-title  mt-2 text-blue">
                        Principal Released
                      </div>{" "}
                      <span className="number-font1 text-center">
                        <Loader
                          type="TailSpin"
                          color="#fff"
                          height={10}
                          width={10}
                        />
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8 col-md-12 col-lg-12">
          <div className="card">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0 border-right">
                <div className="card-body text-center">
                  <h6 className="mb-0">Fully Paid Loans</h6>
                  <h2 className="mb-1 mt-2 number-font text-primary">
                    <span className="counter">
                      <Loader
                        type="TailSpin"
                        color="#2da3e0"
                        height={10}
                        width={10}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted"> Ksh</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0 border-right">
                <div className="card-body text-center">
                  <h6 className="mb-0">Fully Paid Principal</h6>
                  <h2 className="mb-1 mt-2 number-font text-secondary">
                    <span className="counter">
                      <Loader
                        type="TailSpin"
                        color="#2da3e0"
                        height={10}
                        width={10}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted"> Ksh</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0 border-right">
                <div className="card-body text-center">
                  <h6 className="mb-0">Pending Loans</h6>
                  <h2 className="mb-1 mt-2 number-font text-success">
                    <span className="counter">
                      <Loader
                        type="TailSpin"
                        color="#2da3e0"
                        height={10}
                        width={10}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted">Ksh</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0">
                <div className="card-body text-center">
                  <h6 className="mb-0">Overdue Loans</h6>
                  <h2 className="mb-1 mt-2 number-font text-danger">
                    <span className="counter">
                      <Loader
                        type="TailSpin"
                        color="#2da3e0"
                        height={10}
                        width={10}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted">Ksh</p>
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-4">
            <div className="row ">
              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0 border-right">
                <div className="card-body text-center">
                  <h6 className="mb-0">Active Users</h6>
                  <h2 className="mb-1 mt-2 number-font text-success">
                    <span className="counter">
                      <Loader
                        type="TailSpin"
                        color="#2da3e0"
                        height={10}
                        width={10}
                      />
                    </span>
                  </h2>
                  <div className="mb-0 text-muted">
                    {" "}
                    Total Users{" "}
                    <span className="text-primary">
                      <Loader
                        type="TailSpin"
                        color="#2da3e0"
                        height={10}
                        width={10}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0 border-right">
                <div className="card-body text-center">
                  <h6 className="mb-0">Total Paid Loans</h6>
                  <h2 className="mb-1 mt-2 number-font text-secondary">
                    <span className="counter">
                      <Loader
                        type="TailSpin"
                        color="#2da3e0"
                        height={10}
                        width={10}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted">Ksh</p>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0">
                <div className="card-body text-center">
                  <h6 className="mb-0">Partially Paid Principal</h6>
                  <h2 className="mb-1 mt-2 number-font text-info">
                    <span className="counter">
                      <Loader
                        type="TailSpin"
                        color="#2da3e0"
                        height={10}
                        width={10}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted">Count</p>
                </div>
              </div>

              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0 border-right">
                <div className="card-body text-center">
                  <h6 className="mb-0">Partially Paid Loans</h6>
                  <h2 className="mb-1 mt-2 number-font text-primary">
                    <span className="counter">
                      <Loader
                        type="TailSpin"
                        color="#2da3e0"
                        height={10}
                        width={10}
                      />
                    </span>
                  </h2>
                  <p className="mb-0 text-muted">Count</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    const displayError = dashboard.error && (
      <div className="col-md-12 offset-md-1 mt-4">
        <span className="text-danger">ERROR: {dashboard.error}</span>
      </div>
    );

    const dashvalue = dashboard.itemtotals;

    const displayDashboardTotals = dashboard.itemtotals && (
      <div className="row dash-totals">
        <div className="col-xl-4 col-md-12 col-lg-12">
          <div className="card card card-bordered text-light is-dark h-100">
            <div className="card-body ole-top">
              <div className="ibox teams">
                <div className="ibox-content teams">
                  <div className="teams-board-details">
                    <div className="nk-wg7-stats">
                      <div className="nk-wg7-title text-center">
                        Open Loans Ksh
                      </div>
                      <div className="number-lg amount text-center">
                        <Link to="/loans/open">
                          {numberFormat(dashvalue.data.active_loans_amount)}{" "}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="nk-wg7-stats-group">
                    <div className="nk-wg7-stats w-50 text-center">
                      <div className="nk-wg7-title">Closed Commission</div>
                      <div className="number-av">
                        <Link to="/loans/paid">
                          {numberFormat(dashvalue.data.total_interest_amount)}
                        </Link>
                      </div>
                    </div>
                    <div className="nk-wg7-stats w-50 text-center">
                      <div className="nk-wg7-title">Open Commission</div>
                      <div className="number-av">
                        <Link to="/loans/open">
                          {numberFormat(dashvalue.data.active_loans_interest)}
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-12 col-sm-4">
                      <div className="teams-rank nk-wg7-title mt-2 text-blue text-center">
                        Total Open Loans
                      </div>{" "}
                      <Link to="/loans/open">
                        {" "}
                        <div className="number-font1 text-center">
                          {numberFormat(dashvalue.data.active_loans_count)}
                        </div>{" "}
                      </Link>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="teams-rank nk-wg7-title mt-2 text-blue text-center">
                        Principal Requested
                      </div>{" "}
                      <Link to="/loans/requested">
                        {" "}
                        <div className="number-font1 text-center">
                          {numberFormat(
                            dashvalue.data.total_principal_requested
                          )}
                        </div>{" "}
                      </Link>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="teams-rank nk-wg7-title  mt-2 text-blue text-center">
                        Principal Released
                      </div>{" "}
                      <Link to="/loans/released">
                        <div className="number-font1 text-center">
                          {numberFormat(
                            dashvalue.data.total_principal_released
                          )}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8 col-md-12 col-lg-12">
          <div className="card">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0 border-right">
                <div className="card-body text-center">
                  <h6 className="mb-0">Fully Paid Loans</h6>
                  <h2 className="mb-1 mt-2 number-font text-secondary">
                    <Link to="/loans/paid" className=" text-secondary">
                      <div className="counter">
                        {numberFormat(dashvalue.data.total_amount_fully_repaid)}
                      </div>
                    </Link>
                  </h2>
                  <p className="mb-0 text-muted"> Ksh</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0 border-right">
                <div className="card-body text-center">
                  <h6 className="mb-0">Fully Paid Principal</h6>
                  <h2 className="mb-1 mt-2 number-font text-success">
                    <Link to="/loans/paid/1" className=" text-success">
                      {" "}
                      <div className="counter">
                        {numberFormat(
                          dashvalue.data.total_repaid_fully_principal
                        )}
                      </div>
                    </Link>
                  </h2>
                  <p className="mb-0 text-muted">Ksh</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0 border-right">
                <div className="card-body text-center">
                  <h6 className="mb-0">Pending Loans</h6>
                  <h2 className="mb-1 mt-2 number-font text-primary">
                     <Link to="/loans/pending" className=" text-warning">
                      {" "}
                      <span className="counter">
                        {" "}
                        {numberFormat(dashvalue.data.total_amount_to_be_repaid)}
                      </span>
                    </Link>
                  </h2>
                  <p className="mb-0 text-muted"> Ksh</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0">
                <div className="card-body text-center">
                  <h6 className="mb-0">Overdue Loans</h6>
                  <h2 className="mb-1 mt-2 number-font text-danger">
                    <Link to="/loans/overdue" className="text-danger">
                      <div className="counter">
                        {numberFormat(dashvalue.data.over_due_loans_amount)}
                      </div>{" "}
                    </Link>
                  </h2>
                  <p className="mb-0 text-muted">
                    Count ({numberFormat(dashvalue.data.over_due_loans_count)})
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="card mt-4">
            <div className="row ">
              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0 border-right">
                <div className="card-body text-center">
                  <h6 className="mb-0">Active Users</h6>
                  <h2 className="mb-1 mt-2 number-font text-success">
                    <Link to="/borrowers/1" className="text-success">
                      {" "}
                      <div className="counter">
                        {numberFormat(dashvalue.data.total_active_users)}
                      </div>
                    </Link>
                  </h2>
                  <p className="mb-0 text-muted">
                    {" "}
                    Total Users{" "}
                    <Link to="/borrowers" className="text-primary">
                      <span className="text-primary">
                        {numberFormat(dashvalue.data.total_users)}
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0 border-right">
                <div className="card-body text-center">
                  <h6 className="mb-0">Total Paid Loans</h6>
                  <h2 className="mb-1 mt-2 number-font text-primary">
                    <Link to="/loans/paid" className="text-primary">
                      <div className="counter">
                        {numberFormat(
                          dashvalue.data.total_count_fully_repaid_loans
                        )}
                      </div>
                    </Link>
                  </h2>
                  <p className="mb-0 text-muted">Count</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0 border-right">
                <div className="card-body text-center">
                  <h6 className="mb-0">Partially Paid Principal</h6>
                  <h2 className="mb-1 mt-2 number-font text-secondary">
                    <Link to="/loans/partial" className="text-secondary">
                      {" "}
                      <div className="counter">
                        {numberFormat(
                          dashvalue.data.total_count_partial_repaid_loans
                        )}
                      </div>
                    </Link>
                  </h2>
                  <p className="mb-0 text-muted">Count</p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-6 col-sm-6 pr-0 pl-0">
                <div className="card-body text-center">
                  <h6 className="mb-0">Partially Paid Loans</h6>
                  <h2 className="mb-1 mt-2 number-font text-info">
                    <Link to="/loans/partial" className="text-info">
                      {" "}
                      <div className="counter">
                        {numberFormat(
                          dashvalue.data.total_amount_partial_repaid
                        )}
                      </div>
                    </Link>
                  </h2>
                  <p className="mb-0 text-muted">Ksh</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        {displayLoading}
        {displayError}
        {displayDashboardTotals}
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { dashboard } = state;
  return { dashboard };
}

const actionCreators = {
  getDashboardTotals: dashboardActions.getDashboardTotals,
};

const connectedDashboardTotals = connect(
  mapState,
  actionCreators
)(DashboardTotals);
export { connectedDashboardTotals as DashboardTotals };
