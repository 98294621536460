import { API_URL } from "../_config/env";
import { authHeader } from "../_helpers";
import axios from "axios";

export const borrowerService = {
  getAllBorrowers,
  logout,
  getBorrowerById,
  createCustomer,
  updateCustomer,
  sendOtp
};

function getAllBorrowers(
  pageNumber,
  id_no,
  phone_no,
  email,
  name,
  status,
  start_create_date,
  end_create_date
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/users?page=${pageNumber}&id_no=${id_no}&phone_no=${phone_no}&loan_status=${status}&name=${name}&email=${email}&start_create_date=${start_create_date}&end_create_date=${end_create_date}`,
    requestOptions
  ).then(handleResponse);
}

/*
 repayment,
  loan_status,
  start_loan_requested_date,
  end_loan_requested_date
*/
function getBorrowerById(
  id,
  loan_status,
  repayment,
  start_loan_requested_date,
  end_loan_requested_date,
  mpesa_code,
  transaction_code
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/users/${id}?loan_status=${loan_status}&repayment=${repayment}&start_loan_requested_date=${start_loan_requested_date}&end_loan_requested_date=${end_loan_requested_date}&mpesa_code=${mpesa_code}&transaction_code=${transaction_code}`,
    requestOptions
  ).then(handleResponse);
}

function createCustomer(
  first_name,
  last_name,
  phone_no,
  id_no,
  dob,
  gender,
  email,
  alternative_phone_no,
  credit_score
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  let payload = {
    first_name,
    last_name,
    phone_no,
    id_no,
    dob,
    gender,
    email,
    alternative_phone_no,
    credit_score,
  };
  //console.log("Payload ", payload);
  let user = JSON.parse(localStorage.getItem("user"));

  var headers = {
    Authorization: "Bearer " + user.data.token,
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  };

  return axios
    .post(
      `${API_URL}/register_user`,
      {
        first_name,
        last_name,
        phone_no,
        id_no,
        dob,
        gender,
        email,
        alternative_phone_no,
        credit_score,
      },
      {
        headers: {
          Authorization: "Bearer " + user.data.token,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }
    )
    .then(handleResponse);

 
}

function updateCustomer(
  customer_id,
  loan_limit,
  otp
) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  //console.log("Payload ", payload);
  let user = JSON.parse(localStorage.getItem("user"));

  var headers = {
    Authorization: "Bearer " + user.data.token,
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  };

  return axios
    .put(
      `${API_URL}/admin/customer/${customer_id}/update`,
      {
        customer_id: customer_id,
        loan_limit: loan_limit,
        otp: otp
      },
      {
        headers: {
          Authorization: "Bearer " + user.data.token,
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      }
    )
    .then(handleResponse);

}

function sendOtp(){
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
  };

  //console.log("Payload ", payload);
  let user = JSON.parse(localStorage.getItem("user"));

  var headers = {
    Authorization: "Bearer " + user.data.token,
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json",
  };

  return fetch(
      `${API_URL}/admin/customer/otp`,
      requestOptions
    )
    .then(handleResponse);

}

function logout() {
  localStorage.removeItem("user");
}

function handleResponse(response) {
  
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        //auto logout
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
