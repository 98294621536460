export const userConstants = {
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  LOGOUT: "USERS_LOGOUT",

  NEW_USER_REQUEST: "NEW_USER_REQUEST",
  NEW_USER_SUCCESS: "NEW_USER_SUCCESS",
  NEW_USER_FAILURE: "NEW_USER_FAILURE",

  GETALL_USERS_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_USERS_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_USERS_FAILURE: "USERS_GETALL_FAILURE",

  USER_VALID_REQUEST: "USER_VALID_REQUEST",
  USER_VALID_SUCCESS: "USER_VALID_SUCCESS",
  USER_VALID_FAILURE: "USER_VALID_FAILURE",
};
