export const borrowerConstants = {
  GETALL_BORROWERS_REQUEST: "BORROWERS_GETALL_REQUEST",
  GETALL_BORROWERS_SUCCESS: "BORROWERS_GETALL_SUCCESS",
  GETALL_BORROWERS_FAILURE: "BORROWERS_GETALL_FAILURE",

  GETBYID_BORROWER_REQUEST: "BORROWERS_GETBYID_REQUEST",
  GETBYID_BORROWER_SUCCESS: "BORROWERS_GETBYID_SUCCESS",
  GETBYID_BORROWER_FAILURE: "BORROWERS_GETBYID_FAILURE",

  NEW_CUSTOMER_REQUEST: "NEW_CUSTOMER_REQUEST",
  NEW_CUSTOMER_SUCCESS: "NEW_CUSTOMER_SUCCESS",
  NEW_CUSTOMER_FAILURE: "NEW_CUSTOMER_FAILURE",

  UPDATE_CUSTOMER_REQUEST: "UPDATE_CUSTOMER_REQUEST",
  UPDATE_CUSTOMER_SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
  UPDATE_CUSTOMER_FAILURE: "UPDATE_CUSTOMER_FAILURE",

  SEND_OTP_REQUEST: "SEND_OTP_REQUEST",
  SEND_OTP_SUCCESS: "SEND_OTP_SUCCESS",
  SEND_OTP_FAILURE: "SEND_OTP_FAILURE"
};
