import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ShimmerEffect from "../../_components/ShimmerEffect";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "@reach/tabs/styles.css";
import Avatar from "react-avatar";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Pagination } from "react-laravel-paginex";
import { userActions } from "../../_actions";
import UserModal from "./UserModal";

class ListUsers extends Component {
  state = {
    user: "",
    showModal: false,
    user_id: "",
  };
  componentDidMount() {
    this.props.getUsers();
  }

  displayLoanModal = (e, user, user_id) => {
    this.setState({
      user: user,
      showModal: true,
      user_id: user_id,
    });
  };
  //Closed Loans

  onClose = () => {
    this.setState({
      user: "",
      showModal: false,
      user_id: "",
    });
  };

  _displayUsers = () => {
    const { users } = this.props;

    if (users.items.data) {
      let usersArray = users.items.data;

      if (usersArray.length !== 0) {
        return users.items.data.map((user, key) => {
          return (
            <React.Fragment key={key}>
              <tr>
                <td>{key + 1}</td>
                <td className="text-primary">
                  <Avatar
                    name={user.first_name + " " + user.last_name}
                    size="30"
                    round={true}
                  />{" "}
                  <Link to="#" className="text-primary">
                    {user.first_name} {user.last_name}
                  </Link>
                </td>
                <td>{user.phone_number}</td>
                <td>{user.email}</td>
                <td>{user.user_type == 1 ? "Agent User" : "Administrator"}</td>
                <td>{user.created_at}</td>
                <td>{user.status}</td>
                <td className="align-items-center">
                  <button
                    className="btn btn-sm btn-outline-primary"
                    data-toggle="modal"
                    data-target={"#" + user.id}
                    onClick={(e) => this.displayLoanModal(e, user, user.id)}
                  >
                    <i className="fa fa-eye"></i> View
                  </button>
                </td>
              </tr>
            </React.Fragment>
          );
          //}
        });
      } else {
        //No data to display
      }
    }
  };
  render() {
    const { users } = this.props;

    const { user, user_id } = this.state;

    const displayLoading = users.loading && (
      <React.Fragment>
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Phone No</th>
              <th>Email</th>
              <th>User Type</th>
              <th>Mmeber Since</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <ShimmerEffect columns={8} rows={3} />
          </tbody>
        </table>
      </React.Fragment>
    );

    const displayUsers = users.items && (
      <React.Fragment>
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Phone No</th>
              <th>Email</th>
              <th>User Type</th>
              <th>Member Since</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>{this._displayUsers()}</tbody>
        </table>
      </React.Fragment>
    );

    const displayError = users.error && (
      <div className="col-md-12 offset-md-1 mt-4">
        <div className="alert alert-danger">
          <span className="text-danger">ERROR: {users.error}</span>
        </div>
      </div>
    );
    return (
      <React.Fragment>
        <UserModal
          userData={user}
          showModal={this.state.showModal}
          onModalClose={this.onClose}
          user_id={user_id}
          name={user.first_name + " " + user.last_name}
        />
        {displayLoading}
        {displayError}
        {displayUsers}
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { users } = state;
  return { users };
}

const actionCreators = (dispatch, props) => {
  return {
    getUsers: () => {
      dispatch(userActions.getUsers());
    },
  };
};

const connectedListUsers = connect(mapState, actionCreators)(ListUsers);
export { connectedListUsers as ListUsers };
