import React, { Component } from "react";

import { DashboardTotals } from "./sections";
import { MonthlyLoans, LoansCollection } from "./charts";

class Dashboard extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing mt-15">
          <div className="widget-content">
            <h2 className="pg-title">Dashboard</h2>
            <DashboardTotals />
          </div>
        </div>

        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
          <div className="row">
            <MonthlyLoans />
            <LoansCollection />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
