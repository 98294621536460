import { loanConstants } from "../_constants";
import { history } from "../_helpers";
import { loanService } from "../_services";

export const activeLoanActions = {
  getActiveLoans
};

function getActiveLoans() {
  return dispatch => {
    dispatch(request());
    loanService.getActiveLoans().then(
      loans => dispatch(success(loans)),
      error => dispatch(failure(error.toString()))
    );
  };
  function request() {
    return {
      type: loanConstants.GETALL_ACTIVELOANS_REQUEST
    };
  }

  function success(loans) {
    return {
      type: loanConstants.GETALL_ACTIVELOANS_SUCCESS,
      loans
    };
  }

  function failure(error) {
    return {
      type: loanConstants.GETALL_ACTIVELOANS_FAILURE,
      error
    };
  }
}
