import React, { Component } from "react";

import { Redirect, Route } from "react-router-dom";

export class PublicRoute extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Route
          {...this.props}
          exact
          render={props => <Component {...props} />}
        />
      </React.Fragment>
    );
  }
}

export default PublicRoute;
