import React, { Component } from "react";
import { Link } from "react-router-dom";
import { APP_URL } from "../../_config/env";
import { connect } from "react-redux";
import { userActions } from "../../_actions";
import { Redirect } from "react-router-dom";
import SessionModal from "./SessionModal";

class Header extends Component {
  state = {
    logoutModal: false,
  };
  componentDidMount() {
    this.props.userValid();

    this.showLogoutModal();
  }

  showLogoutModal = () => {
    const { user, isvalid } = this.props;

    const isValidUser = isvalid.items;

    if (isValidUser !== undefined) {
      if (isValidUser.success === false) {
        this.setState({
          logoutModal: true,
        });
        return <Redirect to="/login" />;
      }
    }
  };
  render() {
    const { user, isvalid } = this.props;

    return (
      <React.Fragment>
        <div className="header-container fixed-top">
          <header className="header navbar navbar-expand-sm">
            <ul className="navbar-item theme-brand flex-row  text-center">
              <li className="nav-item theme-logo">
                <Link to="/">
                  <img
                    src={`${APP_URL}/images/logo.png`}
                    className="navbar-logo"
                    alt="logo"
                  />
                </Link>
              </li>
            </ul>

            <ul className="navbar-item flex-row ml-md-auto">
              <li className="nav-item dropdown user-profile-dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle user"
                  id="userProfileDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="true"
                >
                  You are logged as{" "}
                  {user.data.first_name + " " + user.data.last_name}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-down"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </Link>
                <div
                  className="dropdown-menu position-absolute"
                  aria-labelledby="userProfileDropdown"
                >
                  <div className="">
                    <div className="dropdown-item">
                      <Link to="/profile">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-user"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                          <circle cx="12" cy="7" r="4"></circle>
                        </svg>{" "}
                        My Profile
                      </Link>
                    </div>
                    <div className="dropdown-item">
                      <a className="" href="apps_mailbox.html">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-inbox"
                        >
                          <polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline>
                          <path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path>
                        </svg>{" "}
                        Inbox
                      </a>
                    </div>
                    <div className="dropdown-item">
                      <a className="" href="auth_lockscreen.html">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-lock"
                        >
                          <rect
                            x="3"
                            y="11"
                            width="18"
                            height="11"
                            rx="2"
                            ry="2"
                          ></rect>
                          <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                        </svg>{" "}
                        Lock Screen
                      </a>
                    </div>
                    <div className="dropdown-item">
                      <Link className="" to="/login">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-log-out"
                        >
                          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                          <polyline points="16 17 21 12 16 7"></polyline>
                          <line x1="21" y1="12" x2="9" y2="12"></line>
                        </svg>{" "}
                        Sign Out
                      </Link>
                    </div>
                  </div>
                </div>
              </li>
              <li className="nav-item user-profile-dropdown">
                <Link
                  to="/login"
                  className="nav-link  user"
                  id="userLogoutDropdown"
                >
                  <i className="fa fa-sign-out"></i> Sign Out
                </Link>
              </li>
            </ul>
          </header>
        </div>
        <SessionModal />
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { users, authentication, isvalid } = state;
  const { user } = authentication;
  return { user, users, isvalid };
}

const actionCreators = (dispatch, props) => {
  return {
    userValid: () => {
      dispatch(userActions.userValid());
    },
  };
};

const connectedHeader = connect(mapState, actionCreators)(Header);
export { connectedHeader as Header };
