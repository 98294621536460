import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import PublicRoute from "../PublicRoute/";
import { PrivateRoute } from "../PrivateRoute/";

//Components
/*import Login from "../Login";
import Dashboard from "../Dashboard/";
import BorrowersList from "../Borrowers/ListOfBorrowers";
import BorrowerDetails from "../Borrowers/BorrowerDetails";
import Loans from "../Loans/";*/
import { LoginPage } from "../Login";
import Dashboard from "../Dashboard";
import { BorrowersList, NewCustomer } from "../Borrowers";
import { User } from "../Users";
import { BorrowerDetails } from "../Borrowers/BorrowerDetails";
import { AllLoans } from "../Loans";
import {
  DashboardOpenLoansData,
  DashboardRequestedLoans,
  DashboardReleasedLoans,
  PartiallyPaidLoans,
  FullyPaidLoans,
  OverDueLoans,
  PendingLoans
} from "../Loans/dashboard";
import ViewProfile from "../Profile/ViewProfile";
import UpdatePassword from "../Profile/UpdatePassword";
import { CustomerUpdate } from "../Borrowers/BorrowerUpdate";

const Routes = (props) => (
  <BrowserRouter>
    <Switch>
      {/*Private Routes*/}
      <PrivateRoute
        exact
        path="/dashboard"
        title="Zaracash > Dashboard"
        component={Dashboard}
      />
      <PrivateRoute
        exact
        path="/dashboard"
        title="Zaracash > Dashboard"
        component={Dashboard}
      />
      <PrivateRoute
        exact
        path="/newCustomer"
        title="New Customer"
        component={NewCustomer}
      />
      <PrivateRoute
        exact
        path="/borrowers"
        title="List of Borrowers"
        component={BorrowersList}
      />
      <PrivateRoute
        exact
        path="/users"
        title="List of Users"
        component={User}
      />
      <PrivateRoute
        exact
        path="/borrowers/:id"
        title="Active Borrowers"
        component={BorrowersList}
      />
      <PrivateRoute
        exact
        path="/customer/:id"
        title="User Details"
        component={BorrowerDetails}
      />
      <PrivateRoute
        exact
        path="/customer/update/:id"
        title="User Details"
        component={CustomerUpdate}
      />
      <PrivateRoute
        exact
        path="/loans"
        title="Loan Details"
        component={AllLoans}
      />
      <PrivateRoute
        exact
        path="/loans/open"
        title="Open Loans"
        component={DashboardOpenLoansData}
      />
      <PrivateRoute
        exact
        path="/loans/requested"
        title="Requested Loans"
        component={DashboardRequestedLoans}
      />
      <PrivateRoute
        exact
        path="/loans/released"
        title="Released Loans"
        component={DashboardReleasedLoans}
      />
      <PrivateRoute
        exact
        path="/loans/pending"
        title="Pending Loans"
        component={PendingLoans}
      />
      <PrivateRoute
        exact
        path="/loans/overdue"
        title="Overdue Loans"
        component={OverDueLoans}
      />
      <PrivateRoute
        exact
        path="/loans/paid"
        title="Fully paid Loans"
        component={FullyPaidLoans}
      />
      <PrivateRoute
        exact
        path="/loans/paid/:id"
        title="Fully Paid Loans Principal"
        component={FullyPaidLoans}
      />
      <PrivateRoute
        exact
        path="/loans/partial"
        title="Partially Paid Loans"
        component={PartiallyPaidLoans}
      />
      <PrivateRoute
        exact
        path="/profile"
        title="Admin profile"
        component={ViewProfile}
      />
       <PrivateRoute
        exact
        path="/password/update"
        title="Admin change password"
        component={UpdatePassword}
      />
      <PublicRoute exact path="/login" component={LoginPage} />
      <Redirect from="*" to="/login" component={LoginPage} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
