import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

export class Sidebar extends Component {
  render() {
    return (
      <React.Fragment>
        <nav>
          <div className="sidebar-wrapper sidebar-theme">
            <nav id="sidebar">
              <div className="shadow-bottom"></div>

              <ul
                className="list-unstyled menu-categories"
                id="accordionExample"
              >
                <li className="menu">
                  <NavLink
                    to="/dashboard"
                    data-active="true"
                    aria-expanded="true"
                    className="dropdown-toggle"
                    activeClassName="activeMenu"
                  >
                    <div className="">
                      <span>
                        {" "}
                        <i className="fa fa-home fa-lg"></i> Dashboard
                      </span>
                    </div>
                  </NavLink>
                </li>

                <li className="menu">
                  <NavLink
                    to="/newCustomer"
                    aria-expanded="false"
                    className="dropdown-toggle"
                    activeClassName="activeMenu"
                  >
                    <div className="">
                      <span>
                        {" "}
                        <i className="fa fa-user-plus fa-lg"></i> Add Customer
                      </span>
                    </div>
                    <div></div>
                  </NavLink>
                </li>
                <li className="menu">
                  <NavLink
                    to="/borrowers"
                    aria-expanded="false"
                    className="dropdown-toggle"
                    activeClassName="activeMenu"
                  >
                    <div className="">
                      <span>
                        {" "}
                        <i className="fa fa-list-ul fa-lg"></i> Customers
                      </span>
                    </div>
                    <div></div>
                  </NavLink>
                </li>

                <li className="menu">
                  <NavLink
                    to="/loans"
                    className="dropdown-toggle"
                    activeClassName="activeMenu"
                  >
                    <div className="">
                      <span>
                        {" "}
                        <i className="fa fa-gg fa-lg"></i> Loans
                      </span>
                    </div>
                  </NavLink>
                </li>
                <li className="menu">
                  <NavLink
                    to="/users"
                    className="dropdown-toggle"
                    activeClassName="activeMenu"
                  >
                    <div className="">
                      <span>
                        {" "}
                        <i className="fa fa-users fa-lg"></i> Users
                      </span>
                    </div>
                  </NavLink>
                </li>
                <li className="menu">
                  <NavLink
                    to="/profile"
                    className="dropdown-toggle"
                    activeClassName="activeMenu"
                  >
                    <div className="">
                      <span>
                        {" "}
                        <i className="fa fa-user fa-lg"></i> Profile
                      </span>
                    </div>
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}
