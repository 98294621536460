import React, { Component } from "react";
import { connect } from "react-redux";
import { borrowerActions } from "../../_actions";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";

class NewCustomer extends Component {
  state = {
    first_name: "",
    last_name: "",
    phone_no: "",
    id_no: "",
    dob: "",
    gender: "",
    email: "",
    alternative_phone_no: "",
    credit_score: "",
  };

  onChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  onSubmit = () => {
    const {
      first_name,
      last_name,
      phone_no,
      id_no,
      dob,
      gender,
      email,
      alternative_phone_no,
      credit_score,
    } = this.state;
    this.props.createCustomer(
      first_name,
      last_name,
      phone_no,
      id_no,
      dob,
      gender,
      email,
      alternative_phone_no,
      credit_score
    );
  };

  render() {
    const { newcustomer } = this.props;

    const displayLoading = newcustomer.loading && (
      <div className="col-md-4 offset-md-5 mt-4">
        <Loader type="TailSpin" color="#2da3e0" height={30} width={30} />
      </div>
    );

    const displayError = newcustomer.error && (
      <div className="col-md-12 offset-md-1 mt-4">
        <div className="alert alert-danger">
          <span className="text-danger">ERROR: {newcustomer.error}</span>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
          <div className="widget-content">
            <Tabs>
              <TabList>
                <Tab>New Customer</Tab>
              </TabList>

              <TabPanels style={{ paddingTop: 15 }}>
                <TabPanel>
                  <h2 className="pg-title">New Customer Form</h2>
                  {/*filter*/}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-sm-12">
                          <form className="row">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>First Name</label>

                                <input
                                  type="text"
                                  className="form-control"
                                  name="first_name"
                                  id="first_name"
                                  placeholder=""
                                  value={this.state.first_name}
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Last Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="last_name"
                                  id="last_name"
                                  placeholder=""
                                  value={this.state.last_name}
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Email</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  value={this.state.email}
                                  placeholder=""
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>National ID</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="id_no"
                                  name="id_no"
                                  value={this.state.id_no}
                                  placeholder=""
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Date of Birth</label>
                                <input
                                  type="date"
                                  id="dob"
                                  value={this.state.dob}
                                  className="form-control flatpickr-input active"
                                  name="dob"
                                  placeholder="1990-08-28"
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Gender</label>
                                <select
                                  className="form-control"
                                  data-toggle="select2"
                                  id="gender"
                                  onChange={this.onChange}
                                  name="gender"
                                  value={this.state.gender}
                                >
                                  <option value="">Select</option>

                                  <option value="Male">Male</option>
                                  <option value="Female">Female</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Phone No</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="phone_no"
                                  name="phone_no"
                                  value={this.state.phone_no}
                                  placeholder=""
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Alternative Phone No</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="alternative_phone_no"
                                  name="alternative_phone_no"
                                  value={this.state.alternative_phone_no}
                                  placeholder=""
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>

                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Loan Limit</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="credit_score"
                                  name="credit_score"
                                  value={this.state.credit_score}
                                  placeholder=""
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>

                            <div className="col-lg-6">
                              {displayLoading}
                              {displayError}
                              <div className="form-group mt-30">
                                <button
                                  type="button"
                                  className="btn btn-primary m-1 pull-right createTicket"
                                  onClick={this.onSubmit}
                                >
                                  <i className="fa fa-plus"></i> Add New
                                  Customer
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <br></br>
                  </div>
                  {/*end filter*/}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { newcustomer } = state;
  return { newcustomer };
}

const actionCreators = (dispatch, props) => {
  return {
    createCustomer: (
      first_name,
      last_name,
      phone_no,
      id_no,
      dob,
      gender,
      email,
      alternative_phone_no,
      credit_score
    ) => {
      dispatch(
        borrowerActions.createCustomer(
          first_name,
          last_name,
          phone_no,
          id_no,
          dob,
          gender,
          email,
          alternative_phone_no,
          credit_score
        )
      );
    },
  };
};

const connectedNewCustomer = connect(mapState, actionCreators)(NewCustomer);
export { connectedNewCustomer as NewCustomer };
