import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import { numberFormat } from "../../_components/numberformat";
import ShimmerEffect from "../../_components/ShimmerEffect";
import Avatar from "react-avatar";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { borrowerActions } from "../../_actions";

class BorrowerDetails extends Component {
  state = {
    repayment: "",
    loan_status: "",
    start_loan_requested_date: "",
    end_loan_requested_date: "",
    mpesa_code: "",
    transaction_code: "",
  };
  componentDidMount() {
    this.props.getBorrower(this.props.match.params.id, "", "", "", "", "", "");
  }

  onChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  onSearch = () => {
    //console.log("ID NO is", this.state.id_no);
    const {
      loan_status,
      repayment,
      start_loan_requested_date,
      end_loan_requested_date,
      mpesa_code,
      transaction_code,
    } = this.state;

    this.props.getBorrower(
      this.props.match.params.id,
      loan_status,
      repayment,
      start_loan_requested_date,
      end_loan_requested_date,
      mpesa_code,
      transaction_code
    );
  };

  _displayActiveLoans = () => {
    const { getborrower } = this.props;

    const loan_value = getborrower.item.data[0].loans;

    return loan_value.map((loan, key) => {
      const loanPrincipal = loan.length > 0 ? loan.amount : 0.0;

      const loanAmount = loan.length > 0 ? loan.to_repay_amount : 0.0;
      const paidAmount = loan.length > 0 ? loan.amount_repaid : 0.0;
      const loanStatus = loan.length > 0 ? loan.loan_status : "Inactive";

      return (
        <tr key={key}>
          <td>{key + 1}</td>
          <td>{loan.transaction_code}</td>
          <td>{loan.created_at}</td>
          <td>{loan.proposed_repayment_time}</td>
          <td>{numberFormat(Number(loan.amount).toFixed(2))}</td>
          <td>
            {numberFormat(
              Number(loan.to_repay_amount - loan.amount).toFixed(2)
            )}
          </td>
          <td>{numberFormat(Number(loan.to_repay_amount).toFixed(2))}</td>
          <td>{numberFormat(Number(loan.amount_repaid).toFixed(2))}</td>
          <td>
            {numberFormat(
              Number(loan.to_repay_amount - loan.amount_repaid).toFixed(2)
            )}
          </td>
          <td>{numberFormat(loan.remaining_days)}</td>
          <td className="capitalize">{loan.status}</td>
        </tr>
      );
    });
  };

  render() {
    const { getborrower } = this.props;

    const displayLoading = getborrower.loading && (
      <React.Fragment>
        <table className="table table-bordered table-hover table-striped">
          <thead>
            <tr>
              <th className="text-center">Loan Limit</th>
              <th className="text-center">Total Principal</th>
              <th className="text-center">Total Loans</th>
              <th className="text-center">Total Repayments</th>
              <th className="text-center">Active Principal</th>
              <th className="text-center">Active Loan</th>
            </tr>
          </thead>
          <tbody>
            <ShimmerEffect columns={6} rows={1} />
          </tbody>
        </table>
      </React.Fragment>
    );

    const displayLoadingLoans = getborrower.loading && (
      <React.Fragment>
        <table className="table table-bordered table-hover table-striped">
          <tr>
            <th>#</th>
            <th>Loan ID</th>
            <th>Released</th>
            <th>Maturity</th>

            <th>Principal</th>
            <th>Interest</th>
            <th>Amount</th>
            <th>Paid</th>
            <th>Balance</th>
            <th>Rem Days</th>
            <th>Status</th>
          </tr>
          <tbody>
            <ShimmerEffect columns={11} rows={5} />
          </tbody>
        </table>
      </React.Fragment>
    );
    const displayError = getborrower.error && (
      <div className="col-md-12 offset-md-1 mt-4">
        <span className="text-danger">ERROR: {getborrower.error}</span>
      </div>
    );

    const borrower = getborrower.item;

    const displayBioData = getborrower.item && (
      <React.Fragment>
        <div className="info">
          <div className="row ">
            <div className="col-md-12">
              <div className="table-responsive pt-4">
                <div className="col-xl-12 col-lg-12 col-md-12 mt-md-0 mt-4">
                  <div className="form">
                    <div className="row">
                      <div className="col-sm-3">
                        <div className="form-group">
                          <label htmlFor="fullName">Full Name</label>
                          <p>
                            {borrower.data[0].first_name +
                              " " +
                              borrower.data[0].last_name}
                          </p>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <label className="dob-input">Gender</label>
                        <p>{borrower.data[0].gender}</p>
                      </div>
                      <div className="col-sm-3">
                        <label className="dob-input">Phone No</label>
                        <p>{borrower.data[0].phone_no}</p>
                      </div>

                      <div className="col-sm-3">
                        <label className="dob-input">Email</label>
                        <p>{borrower.data[0].email}</p>
                      </div>
                      <div className="col-sm-3">
                        <label className="dob-input">National ID</label>
                        <p>{borrower.data[0].id_no}</p>
                      </div>
                      <div className="col-sm-2">
                        <label className="dob-input">DOB</label>
                        <p>{borrower.data[0].dob}</p>
                      </div>
                      <div className="col-sm-3">
                        <label className="dob-input">Risk Level</label>
                        <p>{borrower.data[0].risk_level}</p>
                      </div>
                      <div className="col-sm-3">
                        <label className="dob-input">Member Since</label>
                        <p>{borrower.data[0].member_since}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    const full_name = getborrower.item && (
      <h6 className="pg-title">
        <Avatar
          name={borrower.data[0].first_name + " " + borrower.data[0].last_name}
          size="30"
          round={true}
        />{" "}
        {borrower.data[0].first_name + " " + borrower.data[0].last_name}{" "}
        Customer Details
      </h6>
    );

    const diplayActivLoansTop = getborrower.item && (
      <React.Fragment>
        {getborrower.item.data[0].loans.length > 0 ? (
          <div>
            <h2 className="pg-title2">
              Loans Summary{" "}
              <span className="filter">
                <i className="fa fa-angle-double-down"></i>
              </span>
            </h2>
            <table className="table table-bordered table-hover table-striped">
              <thead>
                <tr>
                  <th className="text-center">Loan Limit</th>
                  <th className="text-center">Total Principal</th>
                  <th className="text-center">Total Loans</th>
                  <th className="text-center">Total Repayments</th>
                  <th className="text-center">Active Principal</th>
                  <th className="text-center">Active Loan</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-center">
                    {numberFormat(
                      getborrower.item.data[0].cumulative_values.credit_score
                    )}
                  </td>
                  <td className="text-center">
                    {numberFormat(
                      getborrower.item.data[0].cumulative_values.total_borrowed
                    )}
                  </td>
                  <td className="text-center">
                    {numberFormat(
                      getborrower.item.data[0].cumulative_values
                        .total_to_repay_amount
                    )}
                  </td>
                  <td className="text-center">
                    {numberFormat(
                      getborrower.item.data[0].cumulative_values
                        .total_amount_repaid
                    )}
                  </td>
                  <td className="text-center">
                    {numberFormat(
                      getborrower.item.data[0].cumulative_values
                        .active_loan_amount_to_pay
                    )}
                  </td>
                  <td className="text-center">
                    {numberFormat(
                      getborrower.item.data[0].cumulative_values
                        .active_loan_amount_requested
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <div className="alert alert-info">Oops! No records found</div>
        )}
      </React.Fragment>
    );
    const displayActiveLoans = getborrower.item && (
      <React.Fragment>
        {getborrower.item.data[0].loans.length > 0 ? (
          <div>
            <h2 className="pg-title2" style={{ marginTop: 25 }}>
              Loans History{" "}
              <span className="filter">
                <i className="fa fa-angle-double-down"></i>
              </span>
            </h2>

            <table className="table table-bordered table-hover table-striped">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Loan ID</th>
                  <th>Released</th>
                  <th>Maturity</th>

                  <th>Principal</th>
                  <th>Interest</th>
                  <th>Amount</th>
                  <th>Paid</th>
                  <th>Balance</th>
                  <th>Rem Days</th>

                  <th>Status</th>
                </tr>
              </thead>
              <tbody>{this._displayActiveLoans()}</tbody>
            </table>
          </div>
        ) : (
          <div className="alert alert-info">Oops! No records found</div>
        )}
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <div className="col-xl-12 col-lg-12 col-md-12 layout-spacing">
          <div className="widget-content">
            {full_name}
            <Tabs>
              <TabList>
                <Tab>Loans Details</Tab>

                <Tab>Bio Information</Tab>
              </TabList>

              <TabPanels style={{ paddingTop: 15 }}>
                <TabPanel>
                  {/*filter*/}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-sm-12">
                          <form className="row">
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Loan ID</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="transaction_code"
                                  id="transaction_code"
                                  placeholder=""
                                  value={this.state.transaction_code}
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>MPESA Code</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="mpesa_code"
                                  name="mpesa_code"
                                  value={this.state.mpesa_code}
                                  placeholder=""
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Loan Status</label>
                                <select
                                  className="form-control"
                                  data-toggle="select2"
                                  id="loan_status"
                                  onChange={this.onChange}
                                  name="loan_status"
                                  value={this.state.loan_status}
                                >
                                  <option value="">Select</option>

                                  <option value="disbursed">Active</option>
                                  <option value="repaid">Paid</option>
                                  <option value="partial">
                                    Partial Payment
                                  </option>
                                  <option value="overdue">Overdue</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Repayment Period</label>
                                <select
                                  className="form-control"
                                  onChange={this.onChange}
                                  name="repayment"
                                  value={this.state.repayment}
                                >
                                  <option value="">Select</option>
                                  <option value="7b30d804-8b05-4eb4-9aae-46819a77910e">
                                    15 Days
                                  </option>
                                  <option value="42611c27-310a-4547-82b6-50aa0d05ec4a">
                                    30 Days
                                  </option>
                                  <option value="6525c382-d109-4490-b8e8-2f74dc9afa09">
                                    60 Days
                                  </option>
                                  <option value="af0e248a-e2b2-45a4-b95a-efb61407db88">
                                    90 Days
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>Loan from date</label>
                                <input
                                  type="date"
                                  id="start_loan_requested_date"
                                  value={this.state.start_loan_requested_date}
                                  className="form-control flatpickr-input active"
                                  name="start_loan_requested_date"
                                  placeholder="2019-10-03"
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="form-group">
                                <label>to date</label>
                                <input
                                  type="date"
                                  id="end_loan_requested_date"
                                  value={this.state.end_loan_requested_date}
                                  className="form-control flatpickr-input active"
                                  name="end_loan_requested_date"
                                  placeholder="2019-10-04"
                                  onChange={this.onChange}
                                />
                              </div>
                            </div>
                            <div className="col-lg-3"></div>

                            <div className="col-lg-3">
                              <div className="form-group mt-30">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-block m-1 createTicket"
                                  onClick={this.onSearch}
                                >
                                  <i className="fa fa-search"></i> Filter
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <br></br>
                  </div>
                  {/*end filter*/}
                  <div className="table-responsive widget-content">
                    {displayLoading}
                    {displayError}
                    {diplayActivLoansTop}
                  </div>
                  <div>
                    <br></br>
                  </div>

                  <div className="table-responsive widget-content">
                    {displayLoadingLoans}
                    {displayError}
                    {displayActiveLoans}
                  </div>
                </TabPanel>

                <TabPanel>
                  <h2 className="pg-title2">
                    Bio Information{" "}
                    <span className="filter">
                      <i className="fa fa-angle-double-down"></i>
                    </span>
                  </h2>
                  {displayBioData}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { getborrower } = state;
  return {
    getborrower,
  };
}

/*const actionCreators = {
  getBorrower: borrowerActions.getBorrowerById,
};*/

const actionCreators = (dispatch, props) => {
  return {
    getBorrower: (
      id,
      loan_status,
      repayment,
      start_loan_requested_date,
      end_loan_requested_date,
      mpesa_code,
      transaction_code
    ) => {
      dispatch(
        borrowerActions.getBorrowerById(
          id,
          loan_status,
          repayment,
          start_loan_requested_date,
          end_loan_requested_date,
          mpesa_code,
          transaction_code
        )
      );
    },
  };
};

const connectedBorrowerDetails = connect(
  mapState,
  actionCreators
)(BorrowerDetails);
export { connectedBorrowerDetails as BorrowerDetails };
