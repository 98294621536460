export const dashboardConstants = {
  GET_DASHBOARD_TOTALS_REQUEST: "DASHBOARD_TOTALS_REQUESTT",
  GET_DASHBOARD_TOTALS_SUCCESS: "DASHBOARD_TOTALS_SUCCESS",
  GET_DASHBOARD_TOTALS_FAILURE: "DASHBOARD_TOTALS_FAILURE",

  MONTHLY_LOANS_CHART_REQUEST: "MONTHLY_LOANS_CHART_REQUEST",
  MONTHLY_LOANS_CHART_SUCCESS: "MONTHLY_LOANS_CHART_SUCCESS",
  MONTHLY_LOANS_CHART_FAILURE: "MONTHLY_LOANS_CHART_FAILURE",

  MONTHLY_LOANS_COLLECTION_REQUEST: "MONTHLY_LOANS_COLLECTION_REQUEST",
  MONTHLY_LOANS_COLLECTION_SUCCESS: "MONTHLY_LOANS_COLLECTION_SUCCESS",
  MONTHLY_LOANS_COLLECTION_FAILURE: "MONTHLY_LOANS_COLLECTION_FAILURE",

  OPEN_LOANS_DATA_REQUEST: "OPEN_LOANS_DATA_REQUEST",
  OPEN_LOANS_DATA_SUCCESS: "OPEN_LOANS_DATA_SUCCESS",
  OPEN_LOANS_DATA_FAILURE: "OPEN_LOANS_DATA_FAILURE",

  REQUESTED_LOANS_DATA_REQUEST: "REQUESTED_LOANS_DATA_REQUEST",
  REQUESTED_LOANS_DATA_SUCCESS: "REQUESTED_LOANS_DATA_SUCCESS",
  REQUESTED_LOANS_DATA_FAILURE: "REQUESTED_LOANS_DATA_FAILURE",

  RELEASED_LOANS_DATA_REQUEST: "RELEASED_LOANS_DATA_REQUEST",
  RELEASED_LOANS_DATA_SUCCESS: "RELEASED_LOANS_DATA_SUCCESS",
  RELEASED_LOANS_DATA_FAILURE: "RELEASED_LOANS_DATA_FAILURE",

  FULL_PAID_LOANS_DATA_REQUEST: "FULL_PAID_LOANS_DATA_REQUEST",
  FULL_PAID_LOANS_DATA_SUCCESS: "FULL_PAID_LOANS_DATA_SUCCESS",
  FULL_PAID_LOANS_DATA_FAILURE: "FULL_PAID_LOANS_DATA_FAILURE",

  PARTIAL_PAID_LOANS_DATA_REQUEST: "PARTIAL_PAID_LOANS_DATA_REQUEST",
  PARTIAL_PAID_LOANS_DATA_SUCCESS: "PARTIAL_PAID_LOANS_DATA_SUCCESS",
  PARTIAL_PAID_LOANS_DATA_FAILURE: "PARTIAL_PAID_LOANS_DATA_FAILURE",

  PENDING_LOANS_DATA_REQUEST: "PENDING_LOANS_DATA_REQUEST",
  PENDING_LOANS_DATA_SUCCESS: "PENDING_LOANS_DATA_SUCCESS",
  PENDING_LOANS_DATA_FAILURE: "PENDING_LOANS_DATA_FAILURE",

  GETALL_OVERDUE_LOANS_REQUEST: "OVERDUE_LOANS_GETALL_REQUEST",
  GETALL_OVERDUE_LOANS_SUCCESS: "OVERDUE_LOANS_GETALL_SUCCESS",
  GETALL_OVERDUE_LOANS_FAILURE: "OVERDUE_LOANS_GETALL_FAILURE",
};
