import { dashboardConstants } from "../_constants";
import { dashboardService } from "../_services";
import { history } from "../_helpers";

export const dashboardActions = {
  getDashboardTotals,
  getDashboardMonthlyRelease,
  getDashboardMonthlyCollections,
  getDashboardOpenLoansData,
  getDashboardRequestedLoans,
  getDashboardReleasedLoans,
  getDashboardFullPaidLoans,
  getDashboardPartiallyPaidLoans,
  getDashboardPendingLoans,
  getDashboardOverDueLoans,
};

function getDashboardTotals() {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getDashbordTotals().then(
      (dashboardtotal) => dispatch(success(dashboardtotal)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return {
      type: dashboardConstants.GET_DASHBOARD_TOTALS_REQUEST,
    };
  }

  function success(dashboardtotal) {
    return {
      type: dashboardConstants.GET_DASHBOARD_TOTALS_SUCCESS,
      dashboardtotal,
    };
  }

  function failure(error) {
    return {
      type: dashboardConstants.GET_DASHBOARD_TOTALS_FAILURE,
      error,
    };
  }
}

function getDashboardMonthlyRelease() {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getMonthlyLoansChart().then(
      (monthlyloans) => dispatch(success(monthlyloans)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return {
      type: dashboardConstants.MONTHLY_LOANS_CHART_REQUEST,
    };
  }

  function success(monthlyloans) {
    return {
      type: dashboardConstants.MONTHLY_LOANS_CHART_SUCCESS,
      monthlyloans,
    };
  }

  function failure(error) {
    return {
      type: dashboardConstants.MONTHLY_LOANS_CHART_FAILURE,
      error,
    };
  }
}

function getDashboardMonthlyCollections() {
  return (dispatch) => {
    dispatch(request());
    dashboardService.getMonthlyLoansCollections().then(
      (monthlycollections) => dispatch(success(monthlycollections)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return {
      type: dashboardConstants.MONTHLY_LOANS_COLLECTION_REQUEST,
    };
  }

  function success(monthlycollections) {
    return {
      type: dashboardConstants.MONTHLY_LOANS_COLLECTION_SUCCESS,
      monthlycollections,
    };
  }

  function failure(error) {
    return {
      type: dashboardConstants.MONTHLY_LOANS_COLLECTION_FAILURE,
      error,
    };
  }
}

function getDashboardOpenLoansData(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  return (dispatch) => {
    dispatch(request());
    dashboardService
      .displayOpenLoansData(
        pageNumber,
        id_no,
        phone_no,
        mpesa_code,
        repayment,
        status,
        start_repayment_date,
        end_repayment_date
      )
      .then(
        (openloans) => dispatch(success(openloans)),
        (error) => dispatch(failure(error.toString()))
      );
  };

  //Requested Loans
  function request() {
    return {
      type: dashboardConstants.OPEN_LOANS_DATA_REQUEST,
    };
  }

  function success(openloans) {
    return {
      type: dashboardConstants.OPEN_LOANS_DATA_SUCCESS,
      openloans,
    };
  }

  function failure(error) {
    return {
      type: dashboardConstants.OPEN_LOANS_DATA_FAILURE,
      error,
    };
  }
}

//Requested Loans
function getDashboardRequestedLoans(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  return (dispatch) => {
    dispatch(request());
    dashboardService
      .getRequestedLoans(
        pageNumber,
        id_no,
        phone_no,
        mpesa_code,
        repayment,
        status,
        start_repayment_date,
        end_repayment_date
      )
      .then(
        (requestedloans) => dispatch(success(requestedloans)),
        (error) => dispatch(failure(error.toString()))
      );
  };
  //Requested Loans
  function request() {
    return {
      type: dashboardConstants.REQUESTED_LOANS_DATA_REQUEST,
    };
  }

  function success(requestedloans) {
    return {
      type: dashboardConstants.REQUESTED_LOANS_DATA_SUCCESS,
      requestedloans,
    };
  }

  function failure(error) {
    return {
      type: dashboardConstants.REQUESTED_LOANS_DATA_FAILURE,
      error,
    };
  }
}
//Released Loans
function getDashboardReleasedLoans(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  return (dispatch) => {
    dispatch(request());
    dashboardService
      .getReleasedLoans(
        pageNumber,
        id_no,
        phone_no,
        mpesa_code,
        repayment,
        status,
        start_repayment_date,
        end_repayment_date
      )
      .then(
        (releasedloans) => dispatch(success(releasedloans)),
        (error) => dispatch(failure(error.toString()))
      );
  };
  //Requested Loans
  function request() {
    return {
      type: dashboardConstants.RELEASED_LOANS_DATA_REQUEST,
    };
  }

  function success(releasedloans) {
    return {
      type: dashboardConstants.RELEASED_LOANS_DATA_SUCCESS,
      releasedloans,
    };
  }

  function failure(error) {
    return {
      type: dashboardConstants.RELEASED_LOANS_DATA_FAILURE,
      error,
    };
  }
}

//Fully Paid Loans
function getDashboardFullPaidLoans(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  return (dispatch) => {
    dispatch(request());
    dashboardService
      .getFullPaidLoans(
        pageNumber,
        id_no,
        phone_no,
        mpesa_code,
        repayment,
        status,
        start_repayment_date,
        end_repayment_date
      )
      .then(
        (fullpaidloans) => dispatch(success(fullpaidloans)),
        (error) => dispatch(failure(error.toString()))
      );
  };
  //Requested Loans
  function request() {
    return {
      type: dashboardConstants.FULL_PAID_LOANS_DATA_REQUEST,
    };
  }

  function success(fullpaidloans) {
    return {
      type: dashboardConstants.FULL_PAID_LOANS_DATA_SUCCESS,
      fullpaidloans,
    };
  }

  function failure(error) {
    return {
      type: dashboardConstants.FULL_PAID_LOANS_DATA_FAILURE,
      error,
    };
  }
}
//Partially Paid Loans
function getDashboardPartiallyPaidLoans(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  return (dispatch) => {
    dispatch(request());
    dashboardService
      .getPartiallyPaidLoans(
        pageNumber,
        id_no,
        phone_no,
        mpesa_code,
        repayment,
        status,
        start_repayment_date,
        end_repayment_date
      )
      .then(
        (partiallypaidloans) => dispatch(success(partiallypaidloans)),
        (error) => dispatch(failure(error.toString()))
      );
  };
  //Requested Loans
  function request() {
    return {
      type: dashboardConstants.PARTIAL_PAID_LOANS_DATA_REQUEST,
    };
  }

  function success(partiallypaidloans) {
    return {
      type: dashboardConstants.PARTIAL_PAID_LOANS_DATA_SUCCESS,
      partiallypaidloans,
    };
  }

  function failure(error) {
    return {
      type: dashboardConstants.PARTIAL_PAID_LOANS_DATA_FAILURE,
      error,
    };
  }
}

//Pending Loans
function getDashboardPendingLoans(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  return (dispatch) => {
    dispatch(request());
    dashboardService
      .getPendingLoans(
        pageNumber,
        id_no,
        phone_no,
        mpesa_code,
        repayment,
        status,
        start_repayment_date,
        end_repayment_date
      )
      .then(
        (pendingloans) => dispatch(success(pendingloans)),
        (error) => dispatch(failure(error.toString()))
      );
  };
  //Requested Loans
  function request() {
    return {
      type: dashboardConstants.PENDING_LOANS_DATA_REQUEST,
    };
  }

  function success(pendingloans) {
    return {
      type: dashboardConstants.PENDING_LOANS_DATA_SUCCESS,
      pendingloans,
    };
  }

  function failure(error) {
    return {
      type: dashboardConstants.PENDING_LOANS_DATA_FAILURE,
      error,
    };
  }
}

/**
 * Over due Loans
 */
function getDashboardOverDueLoans(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  return (dispatch) => {
    dispatch(request());
    dashboardService
      .getOverdueLoans(
        pageNumber,
        id_no,
        phone_no,
        mpesa_code,
        repayment,
        status,
        start_repayment_date,
        end_repayment_date
      )
      .then(
        (overdueloans) => dispatch(success(overdueloans)),
        (error) => dispatch(failure(error.toString()))
      );
  };
  //Requested Loans
  function request() {
    return {
      type: dashboardConstants.GETALL_OVERDUE_LOANS_REQUEST,
    };
  }

  function success(overdueloans) {
    return {
      type: dashboardConstants.GETALL_OVERDUE_LOANS_SUCCESS,
      overdueloans,
    };
  }

  function failure(error) {
    return {
      type: dashboardConstants.GETALL_OVERDUE_LOANS_FAILURE,
      error,
    };
  }
}
