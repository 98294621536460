import { API_URL } from "../_config/env";
import { authHeader } from "../_helpers";

export const loanService = {
  getAllLoans,
  getDueLoans,
  getActiveLoans,
  getLoanBorrowing,
  getLoanRepayment,
  logout,
};

function getAllLoans(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/loan?page=${pageNumber}&id_no=${id_no}&phone_no=${phone_no}&mpesa_code=${mpesa_code}&repayment=${repayment}&status=${status}&start_repayment_date=${start_repayment_date}&end_repayment_date=${end_repayment_date}`,
    requestOptions
  ).then(handleResponse);
}

function getDueLoans() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/loan?due_days=5`, requestOptions).then(
    handleResponse
  );
}
function getActiveLoans() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(`${API_URL}/loan?due_days=0`, requestOptions).then(
    handleResponse
  );
}

/**
 *
 * @param {*} id
 */
function getLoanBorrowing(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/loan/${id}?borrowings=${1}&repayments=${0}`,
    requestOptions
  ).then(handleResponse);
}

/**
 *
 * @param {*} id
 */
function getLoanRepayment(id) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    `${API_URL}/loan/${id}?borrowings=${0}&repayments=${1}`,
    requestOptions
  ).then(handleResponse);
}

function logout() {
  localStorage.removeItem("user");
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        //auto logout
        logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
