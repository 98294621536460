import { loanConstants } from "../_constants";
import { history } from "../_helpers";
import { loanService } from "../_services";

export const loanActions = {
  getAllLoans,
  loadNewPage,
  loadExactPage,
  getBorrowingHistory,
  getRepaymentsHistory,
};

/**
 *
 * @param {*} pageNumber
 * @param {*} id_no
 * @param {*} phone_no
 * @param {*} mpesa_code
 * @param {*} repayment
 * @param {*} status
 * @param {*} start_repayment_date
 * @param {*} end_repayment_date
 */
function getAllLoans(
  pageNumber,
  id_no,
  phone_no,
  mpesa_code,
  repayment,
  status,
  start_repayment_date,
  end_repayment_date
) {
  return (dispatch) => {
    dispatch(request());

    loanService
      .getAllLoans(
        pageNumber,
        id_no,
        phone_no,
        mpesa_code,
        repayment,
        status,
        start_repayment_date,
        end_repayment_date
      )
      .then(
        (loans) => dispatch(success(loans)),
        (error) => dispatch(failure(error.toString()))
      );
  };

  function request() {
    return {
      type: loanConstants.GETALL_LOANS_REQUEST,
    };
  }

  function success(loans) {
    return {
      type: loanConstants.GETALL_LOANS_SUCCESS,
      loans,
    };
  }

  function failure(error) {
    return {
      type: loanConstants.GETALL_LOANS_FAILURE,
      error,
    };
  }
}
/**
 * @param {*} id
 */
function getBorrowingHistory(id) {
  return (dispatch) => {
    dispatch(request);
    loanService.getLoanBorrowing(id).then(
      (borrowings) => dispatch(success(borrowings)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return {
      type: loanConstants.GET_BORROWINGS_HISTORY_REQUEST,
    };
  }

  function success(borrowings) {
    return {
      type: loanConstants.GET_BORROWING_HISTORY_SUCCESS,
      borrowings,
    };
  }

  function failure(error) {
    return {
      type: loanConstants.GET_BORROWINGS_HISTORY_FAILURE,
      error,
    };
  }
}

/**
 * @param {*} id
 */
function getRepaymentsHistory(id) {
  return (dispatch) => {
    dispatch(request);
    loanService.getLoanRepayment(id).then(
      (repayments) => dispatch(success(repayments)),
      (error) => dispatch(failure(error.toString()))
    );
  };

  function request() {
    return {
      type: loanConstants.GET_REPAYMENTS_HISTORY_REQUEST,
    };
  }

  function success(repayments) {
    return {
      type: loanConstants.GET_REPAYMENTS_HISTORY_SUCCESS,
      repayments,
    };
  }

  function failure(error) {
    return {
      type: loanConstants.GET_REPAYMENTS_HISTORY_FAILURE,
      error,
    };
  }
}

function loadNewPage(payload) {
  return {
    type: loanConstants.LOAD_NEW_PAGE,
    payload,
  };
}

function loadExactPage(payload) {
  return {
    type: loanConstants.LOAD_EXACT_PAGE,
    payload,
  };
}
