import { borrowerConstants } from "../_constants";

export function borrowers(state = {}, action) {
  switch (action.type) {
    case borrowerConstants.GETALL_BORROWERS_REQUEST:
      return {
        loading: true,
      };
    case borrowerConstants.GETALL_BORROWERS_SUCCESS:
      return {
        items: action.borrowers,
        total: action.borrowers.data.total,
        per_page: action.borrowers.data.per_page,
        current_page: action.borrowers.data.current_page,
      };
    case borrowerConstants.GETALL_BORROWERS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function getborrower(state = {}, action) {
  switch (action.type) {
    case borrowerConstants.GETBYID_BORROWER_REQUEST:
      return {
        loading: true,
        id: action.id,
      };
    case borrowerConstants.GETBYID_BORROWER_SUCCESS:
      return {
        item: action.borrower,
      };
    case borrowerConstants.GETBYID_BORROWER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

/*create customer*/
export function newcustomer(state = {}, action) {
  switch (action.type) {
    case borrowerConstants.NEW_CUSTOMER_REQUEST:
      return {
        loading: true,
      };
    case borrowerConstants.NEW_CUSTOMER_SUCCESS:
      return {
        item: action.newcustomer,
      };
    case borrowerConstants.NEW_CUSTOMER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function updatecustomer(state = {}, action) {
  switch (action.type) {
    case borrowerConstants.UPDATE_CUSTOMER_REQUEST:
      return {
        loading: true,
      };
    case borrowerConstants.UPDATE_CUSTOMER_SUCCESS:
      return {
        item: action.customer,
      };
    case borrowerConstants.UPDATE_CUSTOMER_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function sendotp(state = {}, action) {
  switch (action.type) {
    case borrowerConstants.SEND_OTP_REQUEST:
      return {
        loading: true,
      };
    case borrowerConstants.SEND_OTP_SUCCESS:
      return {
        item: action.response,
        loading: false,
      };
    case borrowerConstants.SEND_OTP_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
