import { dashboardConstants } from "../_constants";

export function dashboard(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.GET_DASHBOARD_TOTALS_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.GET_DASHBOARD_TOTALS_SUCCESS:
      return {
        itemtotals: action.dashboardtotal,
      };
    case dashboardConstants.GET_DASHBOARD_TOTALS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function dashboardMonthlyLoans(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.MONTHLY_LOANS_CHART_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.MONTHLY_LOANS_CHART_SUCCESS:
      return {
        monthlyrelease: action.monthlyloans,
      };
    case dashboardConstants.MONTHLY_LOANS_CHART_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function dashboardMonthlyCollections(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.MONTHLY_LOANS_COLLECTION_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.MONTHLY_LOANS_COLLECTION_SUCCESS:
      return {
        monthlycollection: action.monthlycollections,
      };
    case dashboardConstants.MONTHLY_LOANS_COLLECTION_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

export function openloansData(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.OPEN_LOANS_DATA_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.OPEN_LOANS_DATA_SUCCESS:
      return {
        openloans: action.openloans,
        total: action.openloans.data.total,
        per_page: action.openloans.data.per_page,
        current_page: action.openloans.data.current_page,
        last_page: action.openloans.data.last_page,
      };
    case dashboardConstants.OPEN_LOANS_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
//requested loans
export function requestedloans(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.REQUESTED_LOANS_DATA_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.REQUESTED_LOANS_DATA_SUCCESS:
      return {
        loans: action.requestedloans,
        total: action.requestedloans.data.total,
        per_page: action.requestedloans.data.per_page,
        current_page: action.requestedloans.data.current_page,
        last_page: action.requestedloans.data.last_page,
      };
    case dashboardConstants.REQUESTED_LOANS_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
//Released Loans
export function releasedloans(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.RELEASED_LOANS_DATA_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.RELEASED_LOANS_DATA_SUCCESS:
      return {
        loans: action.releasedloans,
        total: action.releasedloans.data.total,
        per_page: action.releasedloans.data.per_page,
        current_page: action.releasedloans.data.current_page,
        last_page: action.releasedloans.data.last_page,
      };
    case dashboardConstants.RELEASED_LOANS_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
//Overduve Loans
export function overdueloans(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.GETALL_OVERDUE_LOANS_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.GETALL_OVERDUE_LOANS_SUCCESS:
      return {
        loans: action.overdueloans,
        total: action.overdueloans.data.total,
        per_page: action.overdueloans.data.per_page,
        current_page: action.overdueloans.data.current_page,
        last_page: action.overdueloans.data.last_page,
      };
    case dashboardConstants.GETALL_OVERDUE_LOANS_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

//Full Paid Loans
export function fullpaidloans(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.FULL_PAID_LOANS_DATA_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.FULL_PAID_LOANS_DATA_SUCCESS:
      return {
        loans: action.fullpaidloans,
        total: action.fullpaidloans.data.total,
        per_page: action.fullpaidloans.data.per_page,
        current_page: action.fullpaidloans.data.current_page,
        last_page: action.fullpaidloans.data.last_page,
      };
    case dashboardConstants.FULL_PAID_LOANS_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

//Partially Paid Loans
export function partialpaidloans(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.PARTIAL_PAID_LOANS_DATA_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.PARTIAL_PAID_LOANS_DATA_SUCCESS:
      return {
        loans: action.partiallypaidloans,
        total: action.partiallypaidloans.data.total,
        per_page: action.partiallypaidloans.data.per_page,
        current_page: action.partiallypaidloans.data.current_page,
        last_page: action.partiallypaidloans.data.last_page,
      };
    case dashboardConstants.PARTIAL_PAID_LOANS_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}

//Pending Loans
export function pendingloans(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.PENDING_LOANS_DATA_REQUEST:
      return {
        loading: true,
      };
    case dashboardConstants.PENDING_LOANS_DATA_SUCCESS:
      return {
        loans: action.pendingloans,
        total: action.pendingloans.data.total,
        per_page: action.pendingloans.data.per_page,
        current_page: action.pendingloans.data.current_page,
        last_page: action.pendingloans.data.last_page,
      };
    case dashboardConstants.PENDING_LOANS_DATA_FAILURE:
      return {
        error: action.error,
      };
    default:
      return state;
  }
}
