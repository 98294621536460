import React, { Fragment, Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { Header } from "../../_components/Header";
import { Breadcrump } from "../../_components/Breadcrump";
import { Sidebar } from "../../_components/Sidebar";
import { userActions } from "../../_actions/user.actions";
import { Footer } from "../../_components/Footer";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  if (localStorage.getItem("xhrid") === null) {
    return <Redirect to="/login" />;
  }

  return (
    <React.Fragment>
      <Header />
      <div className="main-container" id="container">
        <div className="overlay"></div>
        <div className="search-overlay"></div>

        <div id="content" className="main-content">
          <Sidebar />
          <Breadcrump />
          <div className="layout-px-spacing">
            <div className="row layout-top-spacing">
              <Route
                {...rest}
                render={(props) =>
                  localStorage.getItem("user") ? (
                    <Component {...props} />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/login",
                        state: { from: props.location },
                      }}
                    />
                  )
                }
              />
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </React.Fragment>
  );
};