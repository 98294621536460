import React, { Component } from "react";
import { connect } from "react-redux";
import { borrowerActions } from "../../_actions";
import Loader from "react-loader-spinner";
import Joi from "joi-browser";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";

class CustomerUpdate extends Component {
  state = {
    loan_limit: "",
    otp: "",
    error: {}
  };

  onChange = (e) => {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  schema = {
    loan_limit: Joi.string()
      .required()
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            default:
              err.message = "Loan limit is required!";
              break;
          }
        });
        return errors;
      }),
    otp: Joi.string()
      .required()
      .error(errors => {
        errors.forEach(err => {
          switch (err.type) {
            default:
              err.message = "Otp code is required!";
              break;
          }
        });
        return errors;
      })
  };

  validationProperty = event => {
    const Obj = { [event.target.name]: event.target.value };
    const schema = { [event.target.name]: this.schema[event.target.name] };
    const { error } = Joi.validate(Obj, schema);
    return error ? error.details[0].message : null;
  };

  validate = () => {
    const options = { abortEarly: false };
    const form = {
      loan_limit: this.state.loan_limit,
      otp: this.state.otp
    };
    const { error } = Joi.validate(form, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  sendOtp = () => {
    this.props.sendOtp();
  }

  onSubmit = (e) => {
    e.preventDefault();
    const error = this.validate();

    if (!error) {
      const user_id = this.props.match.params.id;
      const {
        loan_limit,
        otp
      } = this.state;
      this.props.updateCustomer(
        user_id,
        loan_limit,
        otp
      );
    } else {
      this.setState({
        error: error || {}
      });
    }
  };

  render() {
    const { sendotp, updatecustomer } = this.props;

    return (
      <React.Fragment>
        <div className="col-xl-12 col-lg-12 col-sm-12  layout-spacing">
          <div className="widget-content">
            <Tabs>
              <TabList>
                <Tab>Update Customer</Tab>
              </TabList>

              <TabPanels style={{ paddingTop: 15 }}>
                <TabPanel>
                  <h2 className="pg-title">Customer profile form</h2>
                  {/*filter*/}
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-sm-12">
                          <form>
                            <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group">
                                <label>Loan Limit</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="loan_limit"
                                  name="loan_limit"
                                  value={this.state.loan_limit}
                                  placeholder=""
                                  onChange={this.onChange}
                                  error={this.state.error.loan_limit && true}
                                  helpertext={
                                    this.state.error.loan_limit && this.state.error.loan_limit
                                  }
                                />
                                <help>
                                  {this.state.error.loan_limit && this.state.error.loan_limit}
                                </help>
                              </div>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-4">
                              <div className="form-group">
                                <label>OTP</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="otp"
                                  name="otp"
                                  value={this.state.otp}
                                  placeholder=""
                                  onChange={this.onChange}
                                  error={this.state.error.otp && true}
                                  helpertext={
                                    this.state.error.otp && this.state.error.otp
                                  }
                                />
                                <help>
                                  {this.state.error.otp && this.state.error.otp}
                                </help>
                              </div>
                            </div>
                            <div className="col-lg-2">
                              <div className="form-group">
                                {
                                  sendotp.loading ? 
                                     <div className='pull-center btn btn-primary' style={{ marginTop: '30px', width: '100%' }}>
                                        <Loader type="TailSpin" color="#fff" height={20} width={20} />
                                     </div>
                                     :
                                    <button
                                      type="button"
                                      className="btn btn-primary createTicket"
                                      style={{ marginTop: '30px', width: '100%' }}
                                      onClick={this.sendOtp}
                                    >
                                      Send otp
                                    </button>
                                }
                              </div>
                            </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-6">
                              <div className="form-group mt-30">
                                {
                                  updatecustomer.loading ? 
                                    <div className='pull-right btn btn-primary' style={{ marginTop: '30px', marginRight: '40px' }}>
                                       <Loader type="TailSpin" color="#fff" height={20} width={20} />
                                    </div>
                                   :
                                   <button
                                    type="button"
                                    className="btn btn-primary m-1 pull-right createTicket"
                                    onClick={this.onSubmit}
                                  >
                                      Update customer
                                  </button>
                                }
                              </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                               {
                                updatecustomer.error ?
                                    <div className="col-md-6">
                                    {
                                        updatecustomer.error && (
                                          <div className="alert alert-danger">
                                            <span className="text-danger">ERROR: {updatecustomer.error}</span>
                                          </div>
                                        )
                                    }
                                    </div>
                                  :
                                   <div className="col-md-6">
                                     {
                                       updatecustomer.item ? 
                                       <div> 
                                          {
                                              updatecustomer.item && (
                                                <div className={`${updatecustomer.item.success ? 'alert alert-success' : 'alert alert-danger'}`}>
                                                  <span>{updatecustomer.item.message}</span>
                                                </div>
                                            )
                                          }
                                       </div>
                                       : 
                                       ""
                                     }
                                   </div>
                               }
                               {
                                 sendotp.error && (
                                  <div className="col-md-6">
                                    <div className="alert alert-danger">
                                      <span className="text-danger">ERROR: {sendotp.error}</span>
                                    </div>
                                  </div>
                                )
                               }
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <br></br>
                  </div>
                  {/*end filter*/}
                </TabPanel>
              </TabPanels>
            </Tabs>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapState(state) {
  const { updatecustomer, sendotp } = state;
  return { sendotp, updatecustomer };
}

const actionCreators = (dispatch, props) => {
  return {
    updateCustomer: (
      customer_id,
      loan_limit,
      otp
    ) => {
      dispatch(
        borrowerActions.updateCustomer(
          customer_id,
          loan_limit,
          otp
        )
      );
    },
    sendOtp: (() => {
      dispatch(borrowerActions.sendOtp());
    })
  };
};

const connectedCustomerUpdate = connect(mapState, actionCreators)(CustomerUpdate);
export { connectedCustomerUpdate as CustomerUpdate };
